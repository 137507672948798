import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

export default function Youtube(){

    const { dictionary } = useContext(LanguageContext);

    var IdVideo = dictionary.METADATA.VIDEOS.myLink;
    
    var parametersURl = "?autohide=1&rel=0&showinfo=0";

    return(
        <iframe width='100%' height='100%' className='video--youtube'
            src={ "https://www.youtube.com/embed/" + IdVideo + parametersURl }
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen >    
        </iframe>
    )
}