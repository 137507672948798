import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBWJFqoL3Eqrq1-Xq0AHZYuEft8yeq5aFg",
    authDomain: "my-link-2020.firebaseapp.com",
    databaseURL: "https://my-link-2020.firebaseio.com",
    projectId: "my-link-2020",
    storageBucket: "my-link-2020.appspot.com",
    messagingSenderId: "612346676283",
    appId: "1:612346676283:web:747f2e3b914d042a6a881e",
    measurementId: "G-60LSRHWM93"
};

firebase.initializeApp(firebaseConfig);
export default firebase;