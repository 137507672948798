import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

export function EmailInput(props) {

    const { dictionary } = useContext(LanguageContext); // Language Dictionary Context

    // Init state to Email Input
    const [ email, setEmail ] = useState('');

    // Handle Change of Email Input
    const upDateEmail = (e) => {
        setEmail({
            [e.target.name]: e.target.value
        });
    }
    
    // Do something if state changes
    useEffect(() => {
        if(email !== ''){
            props.setEmail(email.email);
        }
    }, [email]);

    return(
        <input className={ props.typeVip ? 'input--vip' : ' input--no-vip' } type="email" id="email" name='email' onChange={ upDateEmail } placeholder={ dictionary.FormClaim.ClaimForm.placeholder.email }/>
    )
}