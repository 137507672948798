import React, { Component } from 'react';

import { withRouter } from "react-router";

import firebase from '../../config/Firebase';

import { BtnBackSwitch, BtnRedirectHome } from '../../controllers/Buttons/Buttons';

import ChooseName from '../../components/forms/ChooseName';
import ClaimForm from '../../components/forms/ClaimForm';
import UpLoadVideo from '../../components/forms/UpLoadVideo';
import Finally from '../../components/forms/Finally';
import { setDocTitle } from '../../functions/Browser';

class ClaimUserVip extends Component {

    constructor(props){
        super(props);
        this.state = {
            typeVip: true,
            userName: '',
            positionForm: 0,
            dataForm: {}
        }

        this.setUserName = this.setUserName.bind(this);
        this.setDataForm = this.setDataForm.bind(this);
        this.setReadyView = this.setReadyView.bind(this);

        localStorage.setItem('location_video', 'claimUser');
    }

    setUserName(name){ 
        var nameTLC = name.toLowerCase(); 
        this.setState({ userName: nameTLC, positionForm: 1 });
    }

    setDataForm(dataForm2){
        this.setState({ dataForm: dataForm2, positionForm: 2 }); 
    }

    setReadyView(flag){
        if(flag){ this.setState({ positionForm: 3 }); }
        else { this.setState({ positionForm: 1 }); }
    }

    getBackPosition = () => {
        // console.log('getBackPosition');
        switch(this.state.positionForm){
            case 1: this.setState({ positionForm: 0 }); 
                break;
            case 2: this.setState({ positionForm: 1 }); 
                break;
            default: console.log('No existe un paso adicional');
                break;
        }
    } 

    setFinish = () => { 
        let data = {
            username: this.state.userName, 
            category: this.state.dataForm.category.category, 
            subcategory: this.state.dataForm.category.subCategory,
            socialmedia: [ 
                { name: this.state.dataForm.social[0].sn, username: this.state.dataForm.social[0].name, followers: this.state.dataForm.social[0].followers }, 
                { name: this.state.dataForm.social[1].sn, username: this.state.dataForm.social[1].name, followers: this.state.dataForm.social[1].followers }, 
                { name: this.state.dataForm.social[2].sn, username: this.state.dataForm.social[2].name, followers: this.state.dataForm.social[2].followers },
                { name: this.state.dataForm.social[3].sn, username: this.state.dataForm.social[3].name, followers: this.state.dataForm.social[3].followers }
            ],
            email: this.state.dataForm.email,
            video: localStorage.getItem('remote_reference'),
            vip : true,
        }
        //this.dataForm( data );
        this.upLoadData( data ); 
        
    } 

    componentDidMount(){ 
        window.scrollTo(0, 0);
        setDocTitle('Reclamar Usuario Vip');
 
        if(sessionStorage.getItem('userNameReserved') !== null){
            // console.log('Session', sessionStorage.getItem('userNameReserved').toString() ); 
            this.setUserName(sessionStorage.getItem('userNameReserved').toString());
            sessionStorage.removeItem('userNameReserved');
            window.sessionStorage.setItem('userNameReservedVipGetBack', false);
        }
    }

    async upLoadData( data ){
        
        var db = await firebase.firestore();
        var usersRef = await db.collection("ClaimUserName");

        usersRef.add( data ).then(
            () => { 
                console.log('Document successfully written!');
                localStorage.removeItem('remote_reference'); 
            }
        ).catch( (error) => { console.log('Error writing document', error); });
        
    }

    render(){
        // console.log('Claim State', this.state);
        return (<>
                <main className='main-container bg--vip'>
                    {
                        {
                            0: <> 
                                <ChooseName setUserName={this.setUserName} typeVip={this.state.typeVip} userName={this.state.userName} /> 
                                <BtnRedirectHome typeVip={true} urlAlone={true} /> 
                            </>,
                            1: <> 
                                <ClaimForm setDataForm={this.setDataForm} userName={this.state.userName} typeVip={this.state.typeVip} />
                                <BtnBackSwitch typeVip={this.state.typeVip} getBackPosition={this.getBackPosition} goToParent={false} /> 
                            </>,
                            2: <UpLoadVideo 
                                    setReadyView={this.setReadyView} 
                                    userName={this.state.userName} 
                                    typeVip={this.state.typeVip} 
                                />,
                            3: <Finally setFinish={this.setFinish} typeVip={this.state.typeVip} />
                        }[this.state.positionForm]
                    }
                </main>
            </>
        );
    }
}

export default withRouter(ClaimUserVip);