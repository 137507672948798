import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { LanguageContext } from '../../../context/LanguageContext';

export default class Infographic extends Component {

    static contextType = LanguageContext;

    render(){
        
        const { dictionary } = this.context; 
        let dataCards; 
        
        if(this.props.horizontal === false){
            dataCards = dictionary.InfoVipSection.filter( (data) => { return data.id === (this.props.Personal ? 2 : 1) } );
        }

        return (
            <>
                <section className='infographic-container' data-aos={'fade-down'} >
                    {   
                        this.props.horizontal ? 
                            <>
                                <InfoCardHorizontal data={this.props.info.infoCards} /> 
                            </>     
                        :
                            dataCards.map( (data, index) => 
                                <React.Fragment key={index} >
                                    <InfoCardVertical data={data.infoCards} />                        
                                </React.Fragment>
                            )
                    }
                </section>
            </>
        )
    }
}

function InfoCardVertical(props) {
    return (
        props.data.map( (item, index) => 
            <div className='infographic-box-v' key={ index }>
                <div className='points-vip'></div>
                <div className={'infographic-box-v__img infographic-box-v__img--vip icon-' + item.cssIcon }></div>
                <div className='infographic-box-v__content '>
                    <strong className='title-small'>{ item.title }</strong>
                    <div className='infographic-box-v__content--vip'>
                        { ReactHtmlParser(item.infoCard) }
                    </div>
                </div>
            </div>
        )
    )    
}

function InfoCardHorizontal(props) {
    return (
        props.data.map( (item, index) => 
            <div className='infographic-box infographic-box--vip' key={index} >
                <div className={'infographic-box__img inf-box__img--vip icon-location-0 icon-info-' + item.cssIcon}> </div>
                <div className='infographic-box__content inf-box__con--vip' id='info-box-con--1'> 
                    { ReactHtmlParser(item.infoCard) }
                </div>
            </div>
        )
    )
}