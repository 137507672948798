import React, { Component } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import Infographic from '../infographics/no-vip/Infographic';

import { WordLine } from '../utilities/BarLine';

export default class InformationProComponent extends Component {

    static contextType = LanguageContext;

    render(){

        const { dictionary } = this.context; 

        let dataRequirements = dictionary.InfoRequirementsPro.filter( (data) => { return data.id === 3 });            
        let dataBenefits = dictionary.InfoBenefitsPro.filter( (data) => { return data.id === 3 });

        return (<>
            {
                <section className='information'>
                    {
                        dataBenefits.map( (data, index) =>
                            <React.Fragment key={index} >
                                <WordLine title={ data.title } cssComplement={'--pro'} />
                                <Infographic horizontal={ true } info={ data } /> 
                            </React.Fragment>                       
                        )
                    }
                    {
                        dataRequirements.map( (data, index) => 
                            <React.Fragment key={ index } >
                                <WordLine title={ data.title } cssComplement={'--pro'} />
                                <div className='modular-container --mod-space'>
                                    <p> { data.description } </p>
                                </div>
                                <Infographic horizontal={true} info={ data } />                        
                            </React.Fragment>
                        )
                    }
                </section>
            }
        </>)
    }
}