/* eslint-disable */ 
import React, { Component } from 'react';
// import videojs from 'video.js'

export default class VideoPlayer extends Component {
  
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });

    // video loaded on website
    this.player.on('loadedmetadata', () => {
      //console.log('loadedmetadata', this.player.cache_);
      console.log('duration', this.player.cache_.duration);
      this.props.getDuration(this.player.cache_.duration);
    });

  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className='modular-container --mod-spaceCamera'>
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js vjs-styles-dimensions vjs-big-play-centered"></video>
        </div>
      </div>
    )
  }
}