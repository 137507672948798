import React, { Component, useState, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import firebase from '../../config/Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { IconWeb } from '../../assets/svgs/Svg';

function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  document.cookie = "__Secure-3PSIDCC=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Host-GAPS=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Host-3PLSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Host-1PLSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "LSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "SIDCC=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Secure-3PAPISID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "SAPISID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "APISID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "ACCOUNT_CHOOSER=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "SSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Secure-3PSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Secure-1PSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "SID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "__Secure-1PAPISID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "HSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "NID=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "1P_JAR=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"

  for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export default class SignInSocial extends Component {
    
    static contextType = LanguageContext;

    constructor(props){
      super(props);        
      this.SignInWith = this.SignInWith.bind(this);

      deleteAllCookies();
    }

    SignInWith = (social) => {

        let provider; 
        console.log('social Network: ', social);

        const user = firebase.auth().currentUser;
        
        if(user){
          console.log('USER USER --> ', user);
          firebase.auth().signOut().then( () => {
            // Sign-out successful.
            console.log('sesion abierta para cerrar');
            alert('hay una sesion abierta ');
          }).catch( (error) => {
            // An error happened.
            console.log('sesion abierta pero con error al cerrar');
          });

        }else{
          switch (social) {
            case 'google': provider = new firebase.auth.GoogleAuthProvider();
                break;
            case 'facebook': provider = new firebase.auth.FacebookAuthProvider(); 
                break;
            case 'apple': provider = new firebase.auth.OAuthProvider('apple.com'); 
                break;
            default: console.log('Error: La opcion para iniciar sesion no se encentra');    
                break;
          }
          console.log('Session cerrada');
          this.session(social, provider);
        }

    }

    async session( social, provider ){
      if(social === 'google' || social === 'facebook'){

        await firebase.auth().signInWithPopup(provider).then( (result) => {
            var isNewUser = result.additionalUserInfo.isNewUser;
            if (isNewUser){  
              console.log('Usuario es nuevo');
              firebase.auth().signOut().then( () => {
                alert('Existe una sesion abierta del usuario que no esta regisrado en My-Link. Por favor inicia sesion con una cuenta enlazada con My-Link.'); 
                deleteAllCookies();   
              }).catch( (error) => {
                // An error happened.
                console.log(error);
              });
              result.user.delete();
             
            }else{ 
              console.log('Usuario NO es nuevo'); 
              this.props.evaluateUser(); 
            } 
        }).catch( function(error) { console.error("Error: ", error); });

      }else{

          firebase.auth().signInWithRedirect(provider);

          firebase.auth()
          .getRedirectResult()
          .then((result) => {
              if (result.credential) {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = credential.accessToken;
                // ...
              }
                // The signed-in user info.
                var user = result.user;
                console.log(user);
                var isNewUser = result.additionalUserInfo.isNewUser;
              
              if (isNewUser){  /*console.log('Usuario es nuevo');*/ result.user.delete(); } 
              else{  /*console.log('Usuario NO es nuevo');*/ this.props.evaluateUser();  } 

          }).catch((error) => {

              console.log(error);

              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // The email of the user's account used.
              var email = error.email;
              // The firebase.auth.AuthCredential type that was used.
              var credential = error.credential;
              // ...
          });

      }
    }

    render(){
        const { dictionary } = this.context; 
        return(<>
            <div className='cards cards--column'>
                <button className='cards__item cards__item--row btn btn--vip btn--large' onClick={ () => this.SignInWith('google')}>
                    <img src={IconWeb.googleSign} alt='Google' className='info-icon info-icon--social'/>
                    { dictionary.GlobalPage.btn.loginGoogle } </button>
                <button className='cards__item cards__item--row btn btn--vip btn--large' onClick={ () => this.SignInWith('facebook')}>
                    <img src={IconWeb.facebookSign} alt='Facebook' className='info-icon info-icon--social' />
                    { dictionary.GlobalPage.btn.loginFacebook } </button>
                {
                    /*<button className='cards__item cards__item--row btn btn--vip btn--large' onClick={ () => this.SignInWith('apple') } >
                    <img src={IconWeb.appleSign} alt='Facebook' className='info-icon info-icon--social' />
                    { dictionary.GlobalPage.btn.loginApple } </button> */
                }

                { /*<Signup evaluateUser={ () => { this.props.evaluateUser() } } /> */} 
            </div>
        </>);
    }
}

/*
// Configuracion de los provedores de acceso de firebase UI 
var uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: async (authResult) => {
        const userInfo = authResult.additionalUserInfo;
        if (userInfo.isNewUser && userInfo.providerId === "password") {
          try {
            await authResult.user.sendEmailVerification();
            console.log("Check your email.");
          } catch (e) {
            console.log(e);
          } 
        }
        return false;
      },
    },
  };
  
  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("Successufully Signed out");
      })
      .catch(function () {
        console.log("Error Signed out");
      });
  };
  
  const Signup = (props) => {
    const [user, setUser] = useState(null);
  
    useEffect( () => {
      const user = firebase.auth().currentUser;

      if(user){
        props.evaluateUser();
      }
      const authObserver = firebase.auth().onAuthStateChanged((user) => {
        setUser(user);
      });
      return authObserver;
    });
  
    console.log("user", user);
  
    if (user) {
      return (
        <>
          <p>
            Welcome, {user.displayName} <br />
            <small>{user.email}</small> <br />
            <button onClick={signOut}>Sign out</button>
          </p>
        </>
      );
    } else {
      return (
        <>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </>
      );
    }
  };
*/