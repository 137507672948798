import UserInfo from '../menu/UserInfo';
import ProfileInfo from '../menu/ProfileInfo';

import { IconWebPng } from '../../assets/images/Images';

function ModalViewProfile(props) {
    
    return (
        <div className='modal'>
            <div data-aos={'zoom-in'} className={ props.typeVip ? 'modal-content modal-content--vip' : 'modal-content modal-content--no-vip' } >
                <ProfileInfo idProfile={ props.profileId } typeVip={ props.typeVip } />
                <BtnsModalView typeVip={ props.typeVip } getBack={ props.getBack } />            
            </div>
        </div>
    )
}

function ModalViewUserInfo(props) {

    return (
        <div className='modal'>
            <div data-aos={'zoom-in'} className='modal-content modal-content--no-vip'>
                <UserInfo idProfile={ props.profileId } />
                <BtnsModalView getBack={ props.getBack  } />
            </div>
        </div>
    )
}

function BtnsModalView(props) {
    return ( <>
        <button onClick={ props.getBack } className='btn-page modal-btn--right-up'>
            <img src={ IconWebPng.exit } alt='exit' className='modal-btn--img' />
        </button>    
    </> )
}

export { ModalViewProfile, ModalViewUserInfo }