// ----------------------------------------------- Cards
// No-Vip
import cardVip1b from './cards/vip/business-45.png';
import cardVip1p from './cards/vip/personal-46.png';

// VIP
import card1b from './cards/business-28.png';
import card1p from './cards/personal-36.png';

// -------------------------------------------- Business Card Menu 

// No-VIP
import card1 from './cards-menu/no-vip/card-1.png';
import card2 from './cards-menu/no-vip/card-2.png';
import card3 from './cards-menu/no-vip/card-3.png';
// VIP 
import cardVip1 from './cards-menu/vip/card-4.png';
import cardVip2 from './cards-menu/vip/card-5.png';
import cardVip3 from './cards-menu/vip/card-6.png';

// -------------------------------------------- Personal Cards Menu
import cardPersonal1 from './cards-menu/personal/personal-04.png';
import cardPersonal2 from './cards-menu/personal/personal-08.png';

// -------------------------------------------- Hands 
import handLeft from './infographics/page/hand-left.png';  
import handLeftGolden from './infographics/page/hand-left-golden.png';
import handRight from './infographics/page/hand-right.png';

// -------------------------------------------- Icons extra

import exit from './exit.png';

let Cards = { 
    card1b,
    card1p,
    cardVip1b,
    cardVip1p
}

let BusinessMenu = {
    card1, card2, card3,
    cardVip1, cardVip2, cardVip3
}

let PersonalMenu = {
    cardPersonal1, cardPersonal2
}

let Hands = { handLeft, handRight, handLeftGolden }

let IconWebPng = { exit }

export { Cards, BusinessMenu, PersonalMenu, Hands, IconWebPng, handLeftGolden }