const validateNavigator = () => {
    var ua = navigator.userAgent.toLowerCase();
    // console.log('navigator.userAgent.', navigator.userAgent);
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    var isApple = ua.indexOf("iPad") > -1 || ua.indexOf("iPod") > -1 || ua.indexOf("iPhone") > -1;

    if(isAndroid || isApple || iOS()){
        // Redirect to ...
        // https://play.google.com/store/apps/details?id=com.my_link
        // https://apps.apple.com/us/app/my-link/id1532466643
        if(isAndroid){
            //window.location = 'https://play.google.com/store/apps/details?id=com.my_link';
        }  
        if(iOS()){
            //window.location = " https://apps.apple.com/us/app/my-link/id1532466643";
        }
    }
}

const setDocTitle = (title) =>{
    document.title = `My-Link | ${title}`
}

const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export { validateNavigator, setDocTitle };
