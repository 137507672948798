import React, { Component } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

import * as FunForm from '../../functions/Form';

export default class ContactUsForm extends Component {
    static contextType = LanguageContext;
    constructor(props){
        super(props);
        this.state = {
            dataContact: {
                name: '',
                lastname: '', 
                email: '',
                description: ''
            },
            errorMsgs: [ false, false, false, false ]
        }
    }

    componentDidMount(){
        FunForm.showErrorMsg(this.state.errorMsgs, true);
    }

    // Handle Change of Inputs by Target Name
    handleChange = (event) => {
        this.setState( prevState => ({
                ...prevState,
            dataContact: {
                ...prevState.dataContact,
                [event.target.name]: event.target.value
            }
        }), () => { 
            
            console.log(this.state.dataContact); 

            let newErrorMsgs = [];
            var dataContact = this.state.dataContact;
    
            if(dataContact.name !== '' ){
                newErrorMsgs[0] = false;
            }else { 
                newErrorMsgs[0] = true;
            }
           
            if(dataContact.lastname !== '' ){
                newErrorMsgs[1] = false;
            }else { 
                newErrorMsgs[1] = true; 
            }
    
            if(FunForm.validateEmail(dataContact.email)){
                newErrorMsgs[2] = false; 
            }else { 
                newErrorMsgs[2] = true; 
            }
    
            if(dataContact.description !== '' ){
                newErrorMsgs[3] = false; 
            }else { 
                newErrorMsgs[3] = true;
            }

            this.setState( prevState => ({
                ...prevState,
                errorMsgs: newErrorMsgs
            }) )
        
        });
    }

    validateDataForm = () => {
        let newErrorMsgs = [];
        var dataContact = this.state.dataContact;

        if(dataContact.name !== '' ){
            newErrorMsgs[0] = false;
        }else { 
            newErrorMsgs[0] = true;
        }
       
        if(dataContact.lastname !== '' ){
            newErrorMsgs[1] = false;
        }else { 
            newErrorMsgs[1] = true; 
        }

        if(FunForm.validateEmail(dataContact.email)){
            newErrorMsgs[2] = false; 
        }else { 
            newErrorMsgs[2] = true; 
        }

        if(dataContact.description !== '' ){
            newErrorMsgs[3] = false; 
        }else { 
            newErrorMsgs[3] = true;
        }
    
        this.setState( prevState => ({
            ...prevState,
            errorMsgs: newErrorMsgs
        }), () => { 
            if(this.state.errorMsgs.every(v => v === false)){ 
                this.props.setDataFormContact(this.state.dataContact);
            }else { 
                FunForm.showErrorMsg(this.state.errorMsgs, false);
            }
        });
    }

    render(){
        const { dictionary } = this.context; 
        return(
            <>
                <div className='modular-container form__content'>
                    
                    <div className='form__block-info text-left'>
                        <div className='form__block'>
                            <label htmlFor='name'> {dictionary.Contact.name} </label>
                            <input type="text" value={ this.state.dataContact.name } className='input--no-vip' name="name" id='name' onChange={ this.handleChange} />
                            <span id='msg-error-0' className={'alert alert--danger alert--no-vip'}> {dictionary.Contact.Validations.nameValid} </span>
                        </div>
                        <div className='form__block form__block--sub-block'>
                            <label htmlFor='lastname'> {dictionary.Contact.lastName} </label>
                            <input type="text" value={ this.state.dataContact.lastname }  className='input--no-vip' name="lastname" id='lastname' onChange={ this.handleChange} />
                            <span id='msg-error-1' className={'alert alert--danger alert--no-vip'}> {dictionary.Contact.Validations.lastNameValid} </span>
                        </div>
                        <div className='form__block-info text-left form__block--sub-block'>
                            <label htmlFor='email' > {dictionary.Contact.email} </label>
                            <input type="email" value={ this.state.dataContact.email }  className='input--no-vip' name="email" id='email' onChange={ this.handleChange} />
                            <span id='msg-error-2' className={'alert alert--danger alert--no-vip'}> {dictionary.Contact.Validations.emailValid} </span>
                        </div>
                        <div className='form__block-info text-left'>
                            <label htmlFor='description'>{ dictionary.Contact.description }</label>
                            <textarea  type="textarea" value={ this.state.dataContact.description } className='input--no-vip' name="description" id='description'
                                rows="10" cols="50" onChange={ this.handleChange} />
                            <span id='msg-error-3' className={'alert alert--danger alert--no-vip'}> {dictionary.Contact.Validations.descripValid} </span>
                        </div>
                    </div>

                    <Modal accept={this.validateDataForm} text={dictionary.Contact.send} dataContact={this.state.dataContact} />
                </div>
            </>
        )
    }
}


class Modal extends React.Component {

    constructor(props) {
      super(props);
      this.state = { visible: false };
    }
  
    show() {
        this.props.accept();
        if(this.props.dataContact.name !== '' && 
                this.props.dataContact.lastname !== '' &&
                    this.props.dataContact.email !== '' &&
                        this.props.dataContact.description !== '' ){
                this.setState({ visible: true });          
        }  

    }
  
    hide() {
      this.setState({ visible: false }, () => {
            this.props.accept();
        });
    }
  
    render() {
      return (
        <div className='modular-container'>
  
            { this.state.visible ? 
                <div className='modal'> 
                  <Rodal visible={this.state.visible} onClose={ this.hide.bind(this) } >
                        <div className='modular-container'>
                            
                            <div className='title'> ¡¡ Exelente !! </div>

                            <div className='content'>
                                <p> ¡Gracias!, Nosotros te notificaremos en breve por correo electronico. </p>
                            </div>

                        </div>
                  </Rodal>
                </div> 
              : '' 
            } 
  
            <button className='btn btn--no-vip btn--form' onClick={ this.show.bind(this) } > { this.props.text } </button>
  
        </div>
      );
    }
  }