import React, { Component } from 'react';

import { BtnBackSwitch } from '../../controllers/Buttons/Buttons';

import UpLoadOpc from './components/UpLoadOpc';
import Player from './components/Player';

import { NameBar, MyLinkBar } from '../utilities/BarLine';

export default class UpLoadVideo extends Component {

    constructor(props){
        super(props);
        this.state = {
            camaraOpc: false,
            files: [],
            stepPosition: 0,
            loading: 100,
        }

        this.setCamaraOpc =  this.setCamaraOpc.bind(this);        
    }

    setCamaraOpc(camaraOpc, files){ 
        this.setState( { camaraOpc: camaraOpc, files: files, stepPosition: 1 } ); 
    }

    getBackPosition = (flag) => {
        if(this.state.stepPosition === 1){
            if(flag){
                this.setState({ stepPosition: 0 });
            } else { console.log('Terminado');
                this.props.setReadyView(true);
            }
        }else {
            this.props.setReadyView(false);
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <>
                <section className='form'>
                    {
                        {
                            0: <> 
                                    <BtnBackSwitch typeVip={ this.state.typeVip } getBackPosition={this.getBackPosition} />
                                    <NameBar userName={ this.props.userName } 
                                        typeVip={ this.props.typeVip } 
                                        typePro={ this.props.typePro }
                                        business={ this.props.business }
                                    />
                                    <UpLoadOpc 
                                        setCamaraOpc={ this.setCamaraOpc } 
                                        camaraOpc={ this.state.camaraOpc } 
                                        typeVip={this.props.typeVip} 
                                    /> 
                               </>,
                            1: <>
                                    <div className='modular-container'>
                                        <MyLinkBar typeVip={ this.props.typeVip } />
                                        <Player 
                                            camaraOpc={ this.state.camaraOpc } 
                                            files={ this.state.files }
                                            loading={ this.state.loading }
                                            getBack={ this.getBackPosition } 
                                            typeVip={ this.props.typeVip }
                                            userName={ this.props.userName } 
                                        />
                                    </div>
                               </>
                        }[this.state.stepPosition]
                    }
                    <input type='file' className='videoFile' accept='video/*' hidden />
                </section> 
            </>
        )
    }
}