import ES_MX from './es.json';

import EN_US from './en.json';

import AL_GE from './al.json';
import FR_FR from './fr.json';
import IT_IT from './it.json';
import PO_PO from './po.json';

export const dictionaryList = { ES_MX, EN_US, AL_GE, FR_FR, IT_IT, PO_PO };

export const languageOptions = {
  ES_MX: 'Español',
  EN_US: 'English',
  AL_GE: 'Aleman',
  FR_FR: 'Frances',
  IT_IT: 'Italiano',
  PO_PO: 'Portugues'
};