import React from 'react';

function NameBar(props) {
    return (
        <> 
            { props.typeVip ? 
                <div className='modular-container line-bar-title'>
                    <div className={ props.typeVip ? 'line-bar line-bar--mini-vip' : 'line-bar line-bar--mini-no-vip' } ></div>
                        <div className={ props.typeVip ? 'line-bar line-bar--input-vip' : 'line-bar line-bar--input-no-vip' }>
                            <div className={ props.typeVip ? 'icon-bar icon-bar--up ico-bar--vip-business' : 'icon-bar icon-bar--up ico-bar--no-vip-business' }></div>
                            <strong className='title-middle'>@{ props.userName }</strong>
                        </div>
                    <div className={ props.typeVip ? 'line-bar line-bar--mini-vip' : 'line-bar line-bar--mini-no-vip' }></div>
                </div>
            :
                props.typePro !== undefined && props.typePro === true ?
                        <>                            
                            <div className='modular-container line-bar-title'>
                                <div className='line-bar line-bar--mini-pro'></div>
                                    <div className='line-bar line-bar--input-pro'>
                                        <div className='icon-bar icon-bar--up ico-bar--no-vip-personal-pro'></div>
                                        <strong className='title-middle'>@{ props.userName }</strong>
                                    </div>
                                <div className='line-bar line-bar--mini-pro'></div>
                            </div>
                        </>
                    :
                        <>                            
                            <div className='modular-container line-bar-title'>
                                <div className='line-bar line-bar--mini-no-vip'></div>
                                    <div className='line-bar line-bar--input-no-vip'>
                                        <div className={ props.business ? 'icon-bar icon-bar--up ico-bar--no-vip-business': 'icon-bar icon-bar--up ico-bar--no-vip-personal'} ></div>
                                        <strong className='title-middle'>@{ props.userName }</strong>
                                    </div>
                                <div className='line-bar line-bar--mini-no-vip'></div>
                            </div>
                        </>
                    /*
                        <>
                            <div className='box-inline box-inl--column'>
                                <div className={ props.typeVip ? 'icon icon-static--vip-business' : 'icon icon-static--logo-my-link' } > </div>
                                <strong className='title-middle'>@{ props.userName }</strong>
                            </div>
                        </>
                    */
            }
        </>
    )
}

function MyLinkBar(props) {
    return (<> <div className={ props.typeVip ? 'icon icon-static--logo-my-link-text-vip' : 'icon icon-static--logo-my-link-text' } > </div> </> )
}

function WordLine(props) {
    return (<> 
        <h2 className={'line-word' + props.cssComplement }> {props.title} </h2>
    </>)
}

function BarSeparationGolden(props){
    return (
        <div className='line-bar line-bar--vip' data-aos={'zoom-in'}> 
            <div className={ 'icon-bar ' + (props.Business ? 'ico-bar--vip-business' : 'ico-bar--vip-personal') }> </div>
        </div>
    )
}

function BarSeparationPro(props){
    return (
        <div className='line-bar line-bar--pro'> <div className={'icon-bar ico-bar--no-vip-personal-pro'}> </div> </div>
    )
}

function BarSeparationNoVipPersonal(props){
    return (
        <div className='line-bar line-bar--no-vip-personal line-bar--mini'> 
            <div className={'icon-bar ico-bar--no-vip-personal'}> </div> 
        </div>
    )
}

function BarSeparationNoVipBusiness(props){
    return (
        <div className='line-bar line-bar--no-vip-business line-bar--mini'> <div className={'icon-bar ico-bar--no-vip-business'}> </div> </div>
    )
}

function BarSeparationNoVipBusinessPlus(props) { 
    return (
        <div className='line-bar line-bar--no-vip-business-plus line-bar--mini'>
            <div className='icon-bar ico-bar--no-vip-business-plus'> </div>
        </div>
    )  
}

function BarSeparationMini(props) {
    return (
        <>
            <div data-aos={'zoom-in'} className={ props.Pro ? 'line-bar line-bar--pro line-bar--mini' : 'line-bar line-bar--no-vip-personal line-bar--mini' } > 
                <div className={ props.Pro ? 'icon-bar ico-bar--no-vip-personal-pro' : 'icon-bar ico-bar--no-vip-personal' } > </div>
            </div>  
        </>  
    )
}

function BarSeparationMiniVip(props){    
    return (
        <>
            <div data-aos={'zoom-in'} className='line-bar line-bar--vip line-bar--mini' > 
                <div className='icon-bar ico-bar--vip'> </div>
            </div>
        </>
    )
}

function BarSeparationMiniBusiness(props){    
    return (
        <>
            <div data-aos={'zoom-in'} className='line-bar line-bar--no-vip-business line-bar--mini' > 
                <div className='icon-bar ico-bar--no-vip-business'> </div>
            </div>
        </>
    )
}

function BarSeparationMiniBusinessPlus(props) {
    return(
        <>
            <div data-aos={'zoom-in'} className='line-bar line-bar--no-vip-business-plus line-bar--mini' > 
                <div className='icon-bar ico-bar--no-vip-business-plus'> </div>
            </div>
        </>
    )
}

export { 
    NameBar, MyLinkBar, WordLine, 
    BarSeparationGolden, BarSeparationPro, 
    BarSeparationMini, BarSeparationNoVipPersonal, 
    BarSeparationNoVipBusiness, BarSeparationMiniBusiness,
    BarSeparationMiniBusinessPlus, BarSeparationNoVipBusinessPlus, 
    BarSeparationMiniVip
};