import React from 'react'; 

function ProgressBar(props){

    return(
            <div className='progress-bar'>
                <div className={props.typeVip ? 'filler filler--vip': 'filler filler--no-vip'} style={{ width: `${props.loading}%` }}></div> 
            </div>
    );
}

export default ProgressBar;