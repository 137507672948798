import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import { LanguageContext } from '../../context/LanguageContext';

import { Characters } from '../utilities/StaticComponents';

import { IconWeb } from '../../assets/svgs/Svg';

export default function Reserve(){
    
    const { dictionary } = useContext(LanguageContext);

    return (
        <section className='full-container' data-aos={'zoom-in'} >
            <div className='modular-container --mod-space'>

                <div className='box-inline box-inl--column'>
                    <h2 className='title-middle'> { dictionary.Sections.Reserve.title } </h2>
                    <p>{ dictionary.Sections.Reserve.question } </p>
                    <p>{ dictionary.Sections.Reserve.answer } </p>
                </div>

                <div className='box-inline box-inl--column'>

                    <div className='cards'>
                        <Link to='Reservar' className='btn btn-row btn--no-vip btn--big'>
                            <img src={IconWeb.claimName} alt='ClaimName' className='info-icon'/> 
                            <p> { dictionary.GlobalPage.btn.claimUser } </p>
                        </Link>
                    </div>

                    <div className='box-inline box-inl--column'>
                        <p className='line-height--p'> { dictionary.Sections.Reserve.applyVip } </p>
                        <div className='cards'>
                            <Link to='Subir-Estatus' className='btn btn-column btn--vip btn--big'>
                                <p> { dictionary.GlobalPage.btn.apply } </p>
                            </Link>
                        </div>
                    </div>
                    
                </div>
            </div>
            <Characters />
        </section>
    );
}