import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import BusinessCategories from '../../language/extra/BusinessCategories';

import ReactHtmlParser from 'react-html-parser';

export default function SelectCategory(props){

    const { dictionary } = useContext(LanguageContext);  // Language Dictionary Context

    // Init state to inputs of Select Tags inside an object: Category and SubCategory
    const [ categoryOpc, setCategoryOpc ] = useState({
        category: {
            category: '', subCategory: ''
        }
    });

    // Handle Change of Select Inputs by Target Name
    const upDateCategory = (e) => {
        setCategoryOpc({
            category: {
                ...categoryOpc.category, [e.target.name]: e.target.value
            }
        });
    }

    // Do something if state changes
    useEffect(() => {
        console.log(categoryOpc);
        if(categoryOpc.category.category !== '' && categoryOpc.category.subCategory !== ''){
            props.setCategory(categoryOpc.category);
        }
    }, [categoryOpc] );

    // Identify the Current Language on web site 
    const idiom = window.localStorage.getItem('rcml-lang') || 'ES_MX';

    // Get First Key Names of BusinessCategories Object 
    const allowedList = Object.getOwnPropertyNames(BusinessCategories).sort();
 
    // Select the Correct Object to show categories acording to Key Name of BusinessCategories Objects and Current Language 
    const FilteredList = Object.keys(BusinessCategories)
        .filter( key => allowedList.includes(key) )
        .reduce( (obj, key) => {
            if(key === idiom){
                obj = BusinessCategories[key];
            }
            return obj;
        }, {} );

    // Validate if the correct object is empty, if it does, then it will set a default object (English Version) to show categories
    const categoriesExists = () => {
        if(Object.keys(FilteredList).length !== 0){ 
            return FilteredList; 
        }
        return BusinessCategories.EN_US; 
    }; const Categories = categoriesExists();
    
    // Declare an array to store the correct object to each Category
    var CategoriesHtml = [];

    // Get Categories according to the corresponding Language
    Object.keys(Categories).forEach(
        function (key) {
            // Set HTML configuration to Option Tag to SubCategories Options
            var categoryOpc = ' <option value="' + key + '" > '+ Categories[key].NAME_FULL +' </option> ';
            // Declare an array to store the correct object to each SubCategory 
            var OptionsSubCategory = [];

            var SubCategory = Categories[key].SUB;  // Get Subcategory Key Name

            // Get SubCategories acording to the corresponding Category
            Object.keys(SubCategory).forEach(
                (key) => {
                    if(SubCategory.hasOwnProperty(key)) {
                        // Set HTML configuration to Option Tag to SubCategories Options
                        var subOpc = ' <option value="'+ key +'" > ' + SubCategory[key] + ' </option> ';
                        // Set values to SubCategories Object  
                        var subCategoyOpc = { key: key, name: SubCategory[key], CategoryOpc: subOpc } 
                        // Set SubCategories object inside an array 
                        OptionsSubCategory.push(subCategoyOpc);
                    }
                }
            )

            // Set values for each key in a new object
            var NewObj = { 
                key: key,
                nameFull: Categories[key].NAME_FULL,
                nameShort: Categories[key].NAME_SHORT,               
                CategoryOpc: categoryOpc, 
                SubCategoryOpc: OptionsSubCategory 
            }

            // Set New Object to an array declared before this function
            CategoriesHtml.push(NewObj);
        }
    )

    function setOptionsSubCategory(){

        // Get SubCategories Options according to Category Selected
        let SubCategories =  CategoriesHtml.filter( (obj) => { return obj.key === categoryOpc.category.category } );

        return(
            <select value={ categoryOpc.category.subCategory }  className={ props.typeVip ? ' input--vip' : ' input--no-vip'} 
                id='subCategory' name='subCategory' onChange={ upDateCategory } >
                <option value="" disabled hidden>{ dictionary.FormClaim.ClaimForm.placeholder.category }</option>
                { 
                    SubCategories.map( (obj) => {
                        return obj.SubCategoryOpc.map( (opc) => {
                            return ReactHtmlParser(opc.CategoryOpc); 
                        });
                    })
                }
            </select>
        )
    }
 
    return(<>
        <div className='form__block'>
            <select value={ categoryOpc.category.category } className={ props.typeVip ? 'input--vip' : ' input--no-vip'} 
                id='category' name='category' onChange={ upDateCategory }>
                <option value="" disabled hidden>{ dictionary.FormClaim.ClaimForm.placeholder.category }</option>
                { 
                    CategoriesHtml.map( (obj) => { return ReactHtmlParser(obj.CategoryOpc); })
                }
            </select>
            
            { categoryOpc.category.category !== "" ? setOptionsSubCategory() : '' }

        </div>

    </>)
}