import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import LanguageSelector from '../../controllers/Language/LanguageSelector';
import { LanguageContext } from '../../context/LanguageContext';

export default function Footer(){

    const { dictionary } = useContext(LanguageContext);

    return(<>
        <footer className='full-container ful-con--row footer-small'>
            <div className='icon icon-static--logo-my-link-gray ico-sta--small'></div>                
            <div className='box-inline box-inl--end'>
                <div className='footer__info'>
                    <Link to="/Contacto" className='footer-link text-small'>{dictionary.Footer.help}</Link>
                    <a href="https://my-link.com/policies/en/privacy/" className='footer-link text-small'>{dictionary.Footer.politics}</a>
                    <a href="https://my-link.com/policies/" className='footer-link text-small'>{dictionary.Footer.terms}</a>
                </div>
                <LanguageSelector />
            </div>
        </footer>       
    </>);   
}

export function MyLinkVipFooter(){
    return(
        <span className='footer--mini icon icon-static--logo-my-link-text-vip'></span>
    )
}