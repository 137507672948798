import React, { Component, useContext } from 'react';
import { Link } from "react-router-dom";

import { LanguageContext } from '../../context/LanguageContext';

import { TitlePersonalOnly, TitleBusinessOnly, TitlePersonalShow, TitleBusinessShow } from '../utilities/Titles';

import { BusinessMenu, PersonalMenu } from "../../assets/images/Images";
import { ModalViewProfile } from '../modal/Modal';
// import { CharactersBusinessVip } from '../utilities/StaticComponents';

export default class ProfilesMenu extends Component {

    constructor(props){
        super(props);
        this.state = {
            profileId: 0,
            showGeneralTitle: true
        }
        this.ShowInformationBy = this.ShowInformationBy.bind(this);
        this.DisShowTitle.bind(this);
        this.getBack.bind(this);
    }

    ShowInformationBy(num){
        if(typeof(this.props.ShowInformation) === 'function' && this.props.ShowInformation !== undefined){
            // Show Information in web site
            this.DisShowTitle();
            this.props.ShowInformation(num);
        }else { // Show Information in a Modal View
            this.setState({ profileId: num }); 
        } 
    }

    DisShowTitle(){
        if(this.state.showGeneralTitle){
            this.setState({ showGeneralTitle: false });
        }
    }
    
    getBack = () => { this.setState({ profileId: 0 }); }

    render(){
        return ( <>
            {   this.props.Business ?
                    <> 
                        <section className={this.props.typeVip ? 'modular-container --mod-space bg--vip' : 'modular-container --mod-space'}>                    
                            {
                                this.props.typeVip ? <TitleBusinessShow typeVip={ this.props.typeVip } showTitle={ this.state.showGeneralTitle } />  
                                : <TitleBusinessShow typeVip={ this.props.typeVip } showTitle={ this.state.showGeneralTitle } /> 
                            }                   
                                <CardsBusiness typeVip={ this.props.typeVip } 
                                    ShowInformation={ this.ShowInformationBy } 
                                />
                            {   
                                this.props.typeVip ? /*<BtnsInfo />*/'' : 
                                    ( this.state.profileId !== 0 && this.state.showGeneralTitle === false ? 
                                        <TitleBusinessOnly profileId={ this.state.profileId } /> : '' )
                            }
                        </section>
                        { this.state.profileId !== 0 ? <ModalViewProfile profileId={this.state.profileId} typeVip={this.props.typeVip} getBack={this.getBack} /> : '' } 
                    </>      
                :
                    <> 
                        <section className={ this.props.typeVip ? 'modular-container --mod-space bg--vip' : 'modular-container --mod-space' } >                    
                            <TitlePersonalShow typeVip={ this.props.typeVip } showTitle={ this.state.showGeneralTitle } />
                            <CardsPersonal ShowInformation={ this.ShowInformationBy } />
                            { this.state.profileId !== 0 && this.state.showGeneralTitle === false ? <TitlePersonalOnly profileId={ this.state.profileId } /> : '' }
                        </section>
                    </>        
            }
            </>
        );
    }
}

class CardsBusiness extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            selected: ['',''],
            cssInteraction: 'title-card',
            cssInteraction2: 'title-card',
            card: 0
        }
    }

    ShowInfo = (num) => { // 1,2 / 4,5   

        var cssInteraction = [];
        cssInteraction = ['title-card--selected','title-card--unselected']; 
        var cssInteractionVariable = 'title-card'; var cssInteractionVariable2 = 'title-card';
/*
        if(num === 1){
            cssInteractionVariable = 'title-card ' + cssInteraction[1];
            cssInteractionVariable2 = 'title-card ' + cssInteraction[2];
        }else{
            cssInteractionVariable = 'title-card ' + cssInteraction[2];
            cssInteractionVariable2 = 'title-card ' + cssInteraction[1];
        }
*/
        if(this.props.typeVip){  
            //var num1 = num;

            let items = ['', '']; 
            items[num] = 'cards__item--selected';
            
            this.setState({ selected: items, cssInteraction: cssInteractionVariable, cssInteraction: cssInteractionVariable  },
                () => this.props.ShowInformation(num)      
            ); 

            //this.props.ShowInformation(num);
        } else{ 
            // Css selected card 
            let items = ['', '']; 
            items[num] = 'cards__item--selected';

            this.setState({ selected: items, cssInteraction: cssInteractionVariable, cssInteraction: cssInteractionVariable },
                () => this.props.ShowInformation(num)      
            );              
        }
    }

    render(){
    
        const { dictionary } = this.context; 
        
        return (
            <div className='cards'>
                
                <div className={'cards__item ' + this.state.selected[1] } onClick={ () => { this.ShowInfo(1) } } > 
                    <img src={ this.props.typeVip ? BusinessMenu.cardVip1 : BusinessMenu.card1 } alt='Empresarial-Negocio' className={ this.props.typeVip ? 'card-icon card-icon--vip' : 'card-icon card-icon--no-vip' } /> 
                    <strong className={ this.state.cssInteraction } > { dictionary.Sections.BusinessMenu.business }</strong>
                </div>
                    
                <div className={'cards__item ' + this.state.selected[2] } onClick={ () => { this.ShowInfo(2) } } > 
                    <img src={ this.props.typeVip ? BusinessMenu.cardVip2 : BusinessMenu.card2 } alt='BusinessPlus' className={ this.props.typeVip ? 'card-icon card-icon--vip' : 'card-icon card-icon--no-vip' }/> 
                    <strong className={ this.state.cssInteraction2 } > { dictionary.Sections.BusinessMenu.businessPlus } </strong>
                </div>

            </div>
        )
    }
}

class CardsPersonal extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            selected: ['',''],
            cssInteraction: 'title-card',
            cssInteraction2: 'title-card',
            card: 0
        }
    }

    ShowInfo = (num) => { // 1,2 
        
        var cssInteraction = [];
        cssInteraction[num] = ['title-card--selected','title-card--unselected']; 
        
        var cssInteractionVariable = 'title-card'; var cssInteractionVariable2 = 'title-card';
/*
        if(num === 1){
            cssInteractionVariable = 'title-card ' + cssInteraction[1];
            cssInteractionVariable2 = 'title-card ' + cssInteraction[2];
        }else {
            cssInteractionVariable = 'title-card ' + cssInteraction[2];
            cssInteractionVariable2 = 'title-card ' + cssInteraction[1];
        }
*/
        // Css selected card 
        let items = ['', ''];
        items[num] = 'cards__item--selected';
        
        this.setState( { selected: items, selectedStep: num, card: num },
            () => this.props.ShowInformation(num)      
        );                  
    }

    render(){
    
        const { dictionary } = this.context; 
        
        return (
            <div className='cards'>
                
                <div className={'cards__item ' + this.state.selected[1] } onClick={ () => { this.ShowInfo(1) } } > 
                    <img src={ PersonalMenu.cardPersonal1 } alt='Personal' className={'card-icon card-icon--no-vip'} /> 
                    <strong className={ this.state.cssInteraction } >{ dictionary.Sections.PersonalMenu.normal }</strong>
                </div>
                    
                <div className={'cards__item ' + this.state.selected[2] } onClick={ () => { this.ShowInfo(2) } } > 
                    <img src={ PersonalMenu.cardPersonal2 } alt='PersonalPro' className={ 'card-icon card-icon--no-vip' } /> 
                    <strong className={ this.state.cssInteraction2 } > { dictionary.Sections.PersonalMenu.pro } </strong>
                </div>

            </div>
        )
    }
}

/*
function BtnsInfo(props) {
    
    const { dictionary } = useContext(LanguageContext);
    
    return (
        <div className='box-inline box-inl--center'>
            <div className='box-icon box-icon--margin'>
                <Link to='Beneficios-Vip-Business' className='btn btn--vip btn--middle btn--vip-line'>{ dictionary.GlobalPage.btn.benefits }</Link>
            </div>
            <div className='box-icon box-icon--margin'>
                <Link to='Requisitos-Vip-Business' className='btn btn--vip btn--middle btn--vip-line'>{ dictionary.GlobalPage.btn.requirements }</Link>
            </div>
        </div>
    )
}
*/