import React, { Component } from 'react';

import firebase from '../../../config/Firebase';
import _s from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import RecordVideo from '../../../plugins-components/RecordVideo';
import VideoPlayer from '../../../plugins-components/VideoPlayer';
import LoadingSteps from './LoadingSteps';

export default class Player extends Component {

    constructor(props){
        super(props);
        // Declare variables to VideoPlayer Option by default 
        var srcVar = '';
        var typeVar = '';

        // Get and Create URL Object if VideoPlayer Option is true to load the video 
        if(this.props.camaraOpc === false){

            srcVar = (URL).createObjectURL(this.props.files[0]); 
            typeVar = this.props.files[0].type;
            
            if(srcVar === '' || typeVar === ''){
                alert('Format Video Not Valid');
                this.props.getBack(true);
            }        
        }

        this.state = {
            videoOk: false,
            upLoadVideo: false,
            videoJsOptions: this.props.camaraOpc ? 
                    {
                        controls: true,
                        bigPlayButton: false,
                        fluid: true,
                        plugins: {
                            record: {
                                audio: true,
                                video: true,
                                maxLength: 20,
                                debug: true
                            }
                        }
                    }
                :
                    {
                        autoplay: false,
                        controls: true,
                        fluid: true,
                        sources: [ {
                            src: srcVar,
                            type: typeVar
                        } ]
                    }
        }
        
    }

    // Validate duration video less than 21 seconds
    validateVideoDuration = (duration) => {
        var durationVideo = Math.round(duration);
        if(durationVideo <= 21) {
            this.setState({ videoOk: true });
            window.sessionStorage.setItem('videoOk', true);
            alert('Tu video dura ' + durationVideo + ' segundos. ');
        } else { 
            this.setState({ videoOk: false }); 
            window.sessionStorage.setItem('videoOk', false);
            alert('Tu video dura más de 20 segundos.'); 
        }
    }

    getBack = (flag) => { 
        this.props.getBack(flag); 
    }

    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    upLoadVideo = () => {
        this.setState({ upLoadVideo: true });
        this.upLoadVideoSrc();         
    }
     
    async upLoadVideoSrc(){

        const file =  this.props.files[0];
        
        if(file){
                
            const ext			=	this.props.files[0].type.split("/").pop();
            const filename		=	`${uuidv4()}.${ext}`;

            const fileUser      =   localStorage.getItem('location_video');
    
            return firebase.storage().ref(fileUser + '/' + filename).put(file)
                .then( (snapshot) => {
                    console.info("Upload complete: =====> ", snapshot);
                    const remote_reference = snapshot.ref.fullPath;
                    localStorage.setItem('remote_reference', remote_reference);
                });
        }
    }

    render(){ 
        return (
            <React.Fragment>
                {   this.props.camaraOpc ? 
                        <>  <RecordVideo { ...this.state.videoJsOptions } getDuration={ this.validateVideoDuration } /> </>
                    : <>  <VideoPlayer { ...this.state.videoJsOptions } getDuration={ this.validateVideoDuration } step={ this.state.upLoadVideo } /> </>
                }
                <LoadingSteps 
                    loading={ this.props.loading } 
                    getBack={ this.getBack } 
                    typeVip={ this.props.typeVip } 
                    videoOk={ this.state.videoOk } 
                    upLoadVideo={ this.upLoadVideo }
                />
            </React.Fragment>
        );
    }
}