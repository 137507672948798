function Store(){
    return (
        <div className='middle-container mid-con--row'>
            <a className='icon icon--play-store' href='play.google.com/store/apps/details?id=com.my_link'> </a>
            <a className='icon icon--apple-store' href='https://apps.apple.com/us/app/my-link/id1532466643'> </a>
        </div>
    );
}

function Characters(){
    return (
        <ul className='modular-container mod-con--row characters'>
            <li id='character-1'></li>
            <li id='character-2'></li>
            <li id='character-3'></li>
            <li id='character-4'></li>
            <li id='character-5'></li>
            <li id='character-6'></li>
        </ul>
    )
}

function CharactersVip(){
    return (
        <ul className='modular-container mod-con--row characters characters--vip'>
            <li id='character-2'></li>
            <li id='character-3'></li>
            <li id='character-4'></li>
            <li id='character-5'></li>
        </ul>
    )
}

function CharactersBusinessVip(){
    return (<>
        <ul className='modular-container mod-con--row characters characters--vip characters--vip-2'>
            <li className='spa'></li>
            <li className='character-6'></li>
            <li className='yate'></li>
            <li className='woman-air'></li>
        </ul>
    </>)
}

function CharactersBusinessVip2(){
    return (
        <ul className='modular-container mod-con--row characters characters--vip'>
            <li className=''></li>
            <li id=''></li>
            <li className=''></li>
            <li className=''></li>
        </ul>
    )
}

export { Store, Characters, CharactersVip, CharactersBusinessVip }