import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LanguageContext } from '../../context/LanguageContext';

import { BarSeparationGolden, BarSeparationPro, 
    BarSeparationNoVipPersonal, BarSeparationNoVipBusiness, 
    BarSeparationNoVipBusinessPlus } from '../utilities/BarLine';

export default function ContactFooterFromVip(props){

    const { dictionary } = useContext(LanguageContext);

    return ( 
    <>
        <footer className='banner footer--banner bg--vip-black'>
            <BarSeparationGolden Business={props.Business} />
            <div className='banner__content'>
                <div className='modular-container --mod-space'>
                    <p> { dictionary.Sections.ContactUsBefore.asUser } </p>
                    <p> { dictionary.Sections.ContactUsBefore.enjoy } </p>
                    <div className='box-inline'>
                        <Link to='/Contacto' className='btn btn--middle btn--vip btn--vip-line'> { dictionary.GlobalPage.btn.contactUs } </Link>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}

export function ContactFooterFromNoVip(props){

    const { dictionary } = useContext(LanguageContext);

    return(<>
        <footer className='banner footer--banner bg--vip'>
            <BarSeparationGolden Business={props.Business}/>
            <div className='banner__content'>
                <div className='modular-container --mod-space'>
                    <p> { dictionary.Sections.ContactUsBefore.asUser } </p>
                    <p> { dictionary.Sections.ContactUsBefore.enjoy } </p>
                    <div className='box-inline'>
                        <Link to='/Contacto' className={'btn btn--middle btn--vip btn--vip-line'}> { dictionary.GlobalPage.btn.contactUs } </Link>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}

export function ContactFooterFromNoVipPro(props){

    const { dictionary } = useContext(LanguageContext);

    return ( <>
        <footer className='banner footer--banner bg--pro2'>
            <BarSeparationPro />
            <div className='banner__content'>
                <div className='modular-container --mod-space'>
                    <p> { dictionary.Sections.ContactUsBefore.personalPro.dontWait } </p>
                    <div className='box-inline'>
                        <Link to='/Contacto' className='btn btn--middle btn--pro btn--vip-line'> { dictionary.GlobalPage.btn.contactUs } </Link>
                    </div>
                </div>
            </div>
        </footer>
    </> )
}

export function ContactFooterFromNoVipPersonal(props){

    const { dictionary } = useContext(LanguageContext);

    return (
    <>
        <footer className='banner footer--banner'>
            <BarSeparationNoVipPersonal />
            <div className='banner__content'>
                <div className='modular-container --mod-space'>
                    <p> { dictionary.Sections.ContactUsBefore.personal.dontWait } </p>
                    <p> { dictionary.Sections.ContactUsBefore.personal.figure } </p>
                    <div className='box-inline'>
                        <Link to='/Contacto' className='btn btn--middle'> { dictionary.GlobalPage.btn.contactUs } </Link>
                    </div>
                </div>
            </div>
        </footer>
    </> )
}

export function ContactFooterFromNoVipBusiness(props){

    const { dictionary } = useContext(LanguageContext);

    return (
    <>
        <footer className='banner footer--banner'>
            <BarSeparationNoVipBusiness />
            <div className='banner__content'>
                <div className='modular-container --mod-space'>

                    <p className='text-footer-black'>{ dictionary.Sections.ContactUsBefore.personalFirst }</p>
                    
                    <p> { dictionary.Sections.ContactUsBefore.business.dontWait } </p>
                    <p> { dictionary.Sections.ContactUsBefore.business.figure } </p>
                    <p> { dictionary.Sections.ContactUsBefore.business.business } </p>
                    <div className='box-inline'>
                        <Link to='/Contacto' className='btn btn--middle'> { dictionary.GlobalPage.btn.contactUs } </Link>
                    </div>
                </div>
            </div>
        </footer>
    </> )
}

export function ContactFooterFromNoVipBusinessPlus(props){

    const { dictionary } = useContext(LanguageContext);

    return ( <>
        <footer className='banner footer--banner'>
            <BarSeparationNoVipBusinessPlus />
            <div className='banner__content'>
                <div className='modular-container --mod-space'>
                    <p className='text-footer-black'> { dictionary.Sections.ContactUsBefore.personalFirst } </p>
                    
                    <p> { dictionary.Sections.ContactUsBefore.businessPlus.dontWait } </p>
                    <p> { dictionary.Sections.ContactUsBefore.businessPlus.business } </p>
                    <div className='box-inline'>
                        <Link to='/Contacto' className='btn btn--middle'> { dictionary.GlobalPage.btn.contactUs } </Link>
                    </div>
                </div>
            </div>
        </footer>
    </> )
}