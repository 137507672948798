import React from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import { IconWeb } from '../../../assets/svgs/Svg';

export default class UpLoadOpc extends React.Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            camaraOpc: false,
            files: []
        }

        this.galeryOpc = this.galeryOpc.bind(this);
        this.camaraOpc = this.camaraOpc.bind(this);
    }

    galeryOpc(){

        const videoFile = document.querySelector('.videoFile');
        videoFile.click();
               
        // const video = videoFile.split('.').pop();
        localStorage.setItem('videoFile', videoFile);

        videoFile.addEventListener('change', (event) => {
            
            try {
                                                
                const files = Array.from(event.target.files);

                if(files.length !== 0){                    
                    this.setState({ camaraOpc: false, files: files }, () => { 
                        this.props.setCamaraOpc(this.state.camaraOpc, this.state.files);
                    } ); 
                }

            } catch(e) {
                console.log('No hay archivo', e);
            }

        }); 
    }

    camaraOpc(){ 
        this.setState({ camaraOpc: true, files: [] }, () => { 
            this.props.setCamaraOpc(this.state.camaraOpc, this.state.files);
        } ); 
    }

    render(){
        
        const { dictionary } = this.context; 

        return (
            <>
                <div className='modular-container --mod-space'>

                    <div className='form__block'>
                        <p> { dictionary.FormClaim.UpLoadOpc.description } </p>
                        <small> { dictionary.FormClaim.UpLoadOpc.note } </small>
                    </div>
                    
                    <BtnsOpc galeryOpc={this.galeryOpc} camaraOpc={this.camaraOpc} typeVip={this.props.typeVip} dictionary={dictionary.GlobalPage.btn} /> 
                    
                </div>
            </>
        )
    }
}

function BtnsOpc(props) {
    return (<>
        <div className='box-inline form__block-info-isolated'>
            <div className='box-icon'>
                <img className={props.typeVip ? 'icon-page icon-page--circle--vip' : 'icon-page icon-page--circle--no-vip'}  src={IconWeb.gallery} onClick={ props.galeryOpc } alt='Galery-Option' />
                <span className='note note--pro'>{ props.dictionary.gallery }</span>
            </div>
            {
                /*
                    <div className='box-icon'>
                        <img className={props.typeVip ? 'icon-page icon-page--circle--vip' : 'icon-page icon-page--circle--no-vip'} src={IconWeb.camera} onClick={ props.camaraOpc } alt='Camara-Option' />
                        <span className='note'>{ props.dictionary.camera }</span>
                    </div>
                */
            }
        </div>
    </>)
}