import React, { Component } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import  { BtnRedirectHome } from '../../../controllers/Buttons/Buttons';

import Description from '../../../components/infographics/vip/Description';
import Infographic from '../../../components/infographics/vip/Infographic';
import InformationVipComponent from '../../../components/information/InformationVip';
import ContactFooterFromVip from '../../../components/footer/ContactFooter';
import PhraseMyLink from '../../../components/utilities/Phrases';
import { setDocTitle } from '../../../functions/Browser';
import { BarSeparationGolden } from '../../../components/utilities/BarLine';

export default class PersonalVip extends Component{

    static contextType = LanguageContext;

    componentDidMount(){
        window.scrollTo(0, 0);
        setDocTitle('Personal Vip');
    }
    
    render(){

        const { dictionary } = this.context;

        return (
            <>
                <main className='main-container main-container--footer-banner bg--vip'>
                    
                    <div className='box-title box-title--marginTopBottom' data-aos={'zoom-in'} >  
                        <h2 className='title-middle'> { dictionary.Sections.ProfilesMenu.personalVip } </h2>
                    </div>
                    <BarSeparationGolden Business={false} />  

                    <div className='space-container--1'></div>

                    <Description Business={false} WithTitle={false} />
                    <Infographic horizontal={false} Personal={true} />
                    <PhraseMyLink />
                    <InformationVipComponent Business={false} />

                    <BtnRedirectHome typeVip={true} urlAlone={false} />
                </main>
                <ContactFooterFromVip Business={false} />
            </>
        );
    }
}