import React, { Component } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import firebase from '../../config/Firebase';

import SignInSocial from '../../controllers/Login/SignInSocial';
import SignInInputs from '../../controllers/Login/SignInInputs';

export default class Login extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            isLogged: false          
        }

        this.isLogged.bind(this);

        this.evaluateUser = this.evaluateUser.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);  
    }

    async validateUser(userUID){
        var db = await firebase.firestore();
        var usersRef = await db.collection("usuarios");
        var flag = false, data='';
    
        await usersRef.get().then( function(querySnapshot) {
            console.log('size', querySnapshot.size);
            for (var i in querySnapshot.docs) {
                const doc = querySnapshot.docs[i]; // console.log(doc.id, " === FLASE => ", doc.data().correo);
                if ( doc.id === userUID ) {
                    flag = true; 
                    data = doc.data().link;
                    console.log(doc.id, " === TRUE ===> ", doc.data().link);
                    break;
                }
            }
        }).catch( function(error) { 
            console.log('Error -> ID -> ', error); 
        });
    
        return [ flag, data.toString() ];
    }
    
    async evaluateUser(){
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                try{
                    var userUID = user.uid;
                    var flag;
                    flag = this.validateUser(userUID);
    
                    flag.then(
                        (value) => {
                            console.log('value --->', value );
                            if(value[0]){
                                console.log('Observador: User is signed in', value[1]);
                                this.isLogged(true, value[1].toString());
                            } else {
                                console.log('Observador: No se tiene registro del perfil de usuario');
                                /*
                                firebase.auth().signOut().then(() => {
                                    user.delete();
                                    alert('El usuario no esta regisrado en My-Link');
                                    this.isLogged(false, null);
                                }).catch( (error) => {
                                    // An error happened.
                                    console.log(error);
                                });
                                */
                            }
                        }
                    ).catch( (e) => {
                        console.log('Error: La conexion fallo o algo no esta bien', e);
                    });
                }catch(e){
                    console.log('Error: ', e);
                }
            } else {
                // this.isLogged(false, null);
                console.log('Observador: No user is signed in.')
            }
        });
    }

    isLogged(isLoggedFlag, name){
        if(isLoggedFlag){ 
            this.setState({ isLogged: true }, () => { 
                console.log('========= Usuario Logeado', this.state); //
                this.props.setUserName(name); 
            });
        } else{
            this.setState({ isLogged: false }, () => { 
                console.log(' ========= Usuario No Logeado', this.state); 
            });      
        }
    }

    render(){
        const { dictionary } = this.context; 

        return(<>
            <section className='form form--mini'>

                <div className='modular-container'>

                    <div className='icon-static--logo-my-link-vip'></div>

                    <div className='text-center-phrase'>
                        <p className='title-small'> { dictionary.Sections.Login.UpLoadStatus.description } </p>
                        <strong className='title-small'> { dictionary.Sections.Login.UpLoadStatus.note } </strong>
                    </div>

                    <SignInInputs evaluateUser={this.evaluateUser} />
                    <SignInSocial evaluateUser={this.evaluateUser} />

                </div>

            </section>
        </>)
    }
}