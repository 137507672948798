/* eslint-disable */ 
import React, { Component } from 'react';

import 'webrtc-adapter';

export default class RecordVideo extends Component {

    componentDidMount() {
        // instantiate Video.js
        this.player = videojs(this.videoNode, this.props, () => {
            // print version information at startup
            const version_info = 'Using video.js ' + videojs.VERSION +
                ' with videojs-record ' + videojs.getPluginVersion('record') +
                ' and recordrtc ' + RecordRTC.version;
            videojs.log(version_info);
        });

        // device is ready
        this.player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        // user clicked the record button and started recording
        this.player.on('startRecord', () => {
            console.log('started recording!');
        });

        // user completed recording and stream is available
        this.player.on('finishRecord', () => {
            // recordedData is a blob object containing the recorded data that
            // can be downloaded by the user, stored on server etc.
            console.log('finished recording: ', this.player.recordedData);
            console.log('DURATION RECORD ======>' , this.player.cache_);
            //console.log('Fucntion GET', player.record().getDuration());
            this.player.record().saveAs({'video': 'my-link-video.mp4'});

            /*
                  console.log('duration', this.player.cache_.duration);
      this.props.getDuration(this.player.cache_.duration);
            */
        });

        // monitor stream data during recording
        this.player.on('timestamp', function() {
            console.log('current timestamp: ', this.player.currentTimestamp);
            console.log('all timestamps (' + this.player.allTimestamps.length + '): ',
                this.player.allTimestamps);

            // stream data
            console.log('array of blobs: ', this.player.recordedData);
            console.log('---------------------------------------');
        });

        // error handling
        this.player.on('error', (element, error) => {
            console.warn(error);
        });

        this.player.on('deviceError', () => {
            console.error('device error:', this.player.deviceErrorCode);
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        return (
            <div className='modular-container --mod-spaceCamera'>
                <div data-vjs-player>
                    <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-styles-dimensions" playsInline></video>
                </div>
            </div>
        );
    }
}