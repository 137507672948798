import React, { Component, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import firebase from '../../config/Firebase';

import { BtnBackSwitch, BtnRedirectHome } from '../../controllers/Buttons/Buttons';

import ChooseName from '../../components/forms/ChooseName';
import ClaimForm from '../../components/forms/ClaimForm';
import UpLoadVideo from '../../components/forms/UpLoadVideo';
import Finally from '../../components/forms/Finally';
import { setDocTitle } from '../../functions/Browser';

export default class ClaimUser extends Component {

    constructor(props){
        super(props);
    
        //localStorage.setItem('userPro', false);  // Cambiar
        
        this.state = {
            typeVip: false,
            typePro: false,
            business: false,
            userName: '',  // Cambiar 
            positionForm: 0,     // Cambiar
            dataForm: {}
        }

        this.setUserName = this.setUserName.bind(this);
        this.setDataForm = this.setDataForm.bind(this);
        this.setReadyView = this.setReadyView.bind(this);
        this.changePosition = this.changePosition.bind(this);

        localStorage.setItem('location_video', 'claimUser');

    }

    setUserName(name, flagPro, business){ 
        var nameTLC = name.toLowerCase(); 
        this.setState( { userName: nameTLC, positionForm: 1, typePro: flagPro, business: business }, 
            () => { console.log('typePro:' + this.state.typePro ); } );
    }

    setDataForm(dataForm2){ 
        var flag = window.localStorage.getItem('userPro');
        if(flag){
            this.setState({ dataForm: dataForm2, positionForm: 2, typePro: true }); 
        }else {
            this.setState({ dataForm: dataForm2, positionForm: 2, typePro: false }); 
        }
    }

    setReadyView(flag){
        if(flag){ this.setState({ positionForm: 3 }); }
        else { this.setState({ positionForm: 1 }); }
    }

    setFinish = () => { 
        let data = {}
        
        if(this.state.typePro === true){
            data = {
                username: this.state.userName, 
                category: this.state.dataForm.category.category, 
                subcategory: this.state.dataForm.category.subCategory,
                socialmedia: [ 
                    { name: this.state.dataForm.social[0].sn, username: this.state.dataForm.social[0].name, followers: this.state.dataForm.social[0].followers }, 
                    { name: this.state.dataForm.social[1].sn, username: this.state.dataForm.social[1].name, followers: this.state.dataForm.social[1].followers }, 
                    { name: this.state.dataForm.social[2].sn, username: this.state.dataForm.social[2].name, followers: this.state.dataForm.social[2].followers },
                    { name: this.state.dataForm.social[3].sn, username: this.state.dataForm.social[3].name, followers: this.state.dataForm.social[3].followers }
                ],
                email: this.state.dataForm.email,
                video: localStorage.getItem('remote_reference'), 
                pro: true
            }
        }else {
            data = {
                username: this.state.userName, 
                category: this.state.dataForm.category.category, 
                subcategory: this.state.dataForm.category.subCategory,
                socialmedia: [ 
                    { name: this.state.dataForm.social[0].sn, username: this.state.dataForm.social[0].name, followers: this.state.dataForm.social[0].followers }, 
                    { name: this.state.dataForm.social[1].sn, username: this.state.dataForm.social[1].name, followers: this.state.dataForm.social[1].followers }, 
                    { name: this.state.dataForm.social[2].sn, username: this.state.dataForm.social[2].name, followers: this.state.dataForm.social[2].followers },
                    { name: this.state.dataForm.social[3].sn, username: this.state.dataForm.social[3].name, followers: this.state.dataForm.social[3].followers }
                ],
                email: this.state.dataForm.email,
                video: localStorage.getItem('remote_reference'), 
                vip: false
            }
        }

        //this.dataForm();
        this.upLoadData( data );
    } 

    async upLoadData( data ){
        
        var db = await firebase.firestore();
        var usersRef = await db.collection("ClaimUserName");

        usersRef.add( data ).then(
            () => { 
                console.log('Document successfully written!');
                localStorage.removeItem('remote_reference'); 
                localStorage.removeItem('userPro');
            }
        ).catch( (error) => {
            console.log('Error writing document', error); 
        });
        
    }

    componentDidMount(){ 
        window.scrollTo(0, 0); 
        setDocTitle('Reclamar Usuario');
    }

    getBackPosition = () => {
        switch(this.state.positionForm){
            case 1: this.setState({ positionForm: 0, typePro: false });
                    window.localStorage.removeItem('userPro');
                    window.sessionStorage.setItem('ReuseUserName', true); 
                break;
            case 2: this.setState({ positionForm: 1 }); 
                break;
            default: console.log('No existe un paso adicional');
                break;
        }
    } 

    changePosition(){
        switch(this.state.positionForm){
            case 1: this.setState({ positionForm: 0 }); break;
            case 2: this.setState({ positionForm: 1 }); break;
            case 3: this.setState({ positionForm: 2 }); break;
            default: console.log('back ESC');   break;
        }
    }

    render(){
        
        document.addEventListener("keydown", function(event) {
            const key = event.key; // Or const {key} = event; in ES6+
            if(key === "Escape") {
                this.changePosition();
            }
        } );

        return ( <>
            <main className={'main-container ' + (this.state.typePro ? 'bg--pro2' : '') } >                
                {
                    {
                        0: <> 
                            <ChooseName setUserName={this.setUserName} 
                                typeVip={this.state.typeVip} 
                                userName={this.state.userName} /> 

                                <BtnRedirectHome typeVip={false} urlAlone={true} /> 
                        </>,
                        1: <> 
                            <ClaimForm setDataForm={this.setDataForm} 
                                userName={this.state.userName} 
                                typeVip={this.state.typeVip}
                                typePro={ this.state.typePro }
                                business={ this.state.business } /> 

                                <BtnBackSwitch typeVip={this.state.typeVip} getBackPosition={this.getBackPosition} goToParent={false} /> 
                        </>,
                        2: <UpLoadVideo 
                                setReadyView={this.setReadyView} 
                                userName={this.state.userName} 
                                typeVip={this.state.typeVip}
                                typePro={ this.state.typePro }
                                business={ this.state.business } 
                                
                            />,
                        3: <Finally setFinish={this.setFinish} typeVip={this.state.typeVip} />,
                        10: <VipConsideration />
                    }[this.state.positionForm]
                }
            </main>
        </> );
    }
}

function VipConsideration(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <section className='modular-container form__content'>

            <div className='icon icon-static--logo-my-link'></div>

            <div className='form__block-info'>
                <div className='text-center'>
                    <p> { dictionary.FormClaim.ConsiderationVip.consideration } </p>
                    <p> { dictionary.FormClaim.ConsiderationVip.question } </p>
                </div>
                <div className='box-inline'>
                    <button className='btn btn-no--vip btn--large btn--large-full'> { dictionary.GlobalPage.btn.yes } </button>
                    <button className='btn btn-no--vip btn--large btn--large-full'> { dictionary.GlobalPage.btn.no } </button>
                </div>
            </div>

            <div className='form__block-info'>
                <p> { dictionary.FormClaim.ConsiderationVip.note } </p>
                <button className='btn btn-no--vip btn--large'> { dictionary.GlobalPage.btn.sendRequest } </button>
            </div>

        </section>        
    )
}