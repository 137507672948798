import { Cards } from '../../assets/images/Images';
import { MenuVip, MenuNoVip } from '../menu/SectionMenu';

export default function InfographicTypes(){
    
    return(
        <section className='full-container' >
            <MenuNoVip cardPersonal={Cards.card1p} cardBusiness={Cards.card1b} />
            <MenuVip cardPersonal={Cards.cardVip1p} cardBusiness={Cards.cardVip1b}/>
        </section>
    );
}