import { useHistory } from 'react-router-dom';
import { IconWeb } from '../../assets/svgs/Svg';

export function BtnClaimUserVip(props){

  let history = useHistory();

  function handleClick() {
    window.sessionStorage.setItem('userNameReserved', props.nameUser);
    history.push("/Reservar-Vip");
  }

  return(
    <button className='btn btn--vip btn--form btn--form-big btn--vip-line' onClick={ handleClick } > { props.dictionary } </button>    
  )
}

export function BtnRedirectToHomeContact(props){

  let history = useHistory();

  function handleClick() {
    if(props.loadData === true && props.loadData !== undefined){
      setTimeout( function() {
        props.loadDataFunction();
        console.log('time 2s');
        history.push("/");
      }, 2000);
    }else {
      history.push("/");
    }
  }

  return (
    <button type='button' className={ props.typeVip ? 'btn btn--vip btn--large' : 'btn btn-no--vip btn--large' }
      onClick={ handleClick } > { props.dictionary } </button>
  );
}

export function BtnRedirectToHome(props){

  let history = useHistory();

  function handleClick() {
    if(props.loadData === true && props.loadData !== undefined){
      
      setTimeout( function() {
        props.loadDataFunction();
        console.log('time 2s');
        history.push("/");
      }, 2000);
    }else {
      history.push("/");
    }
  }

  return (
    <button type='button' className={ props.typeVip ? 'btn btn--vip btn--large' : 'btn btn-no--vip btn--large' }
      onClick={ handleClick } > { props.dictionary } </button>
  );
}

export function BtnRedirectHome(props){

  let history = useHistory();

  function handleClick() {
    if(props.urlAlone){
      history.goBack();
    }else {
      history.push("/");
    }
  }

  return(
    <div className={ props.typeVip ? 'btn-page--getBack-vip' : 'btn-page--getBack-no-vip' }>
      <img src={IconWeb.leftRow} alt='left-row' onClick={ handleClick } />
    </div>
  );
}

export function BtnsBenefitsRequierements(props){

  let history = useHistory();

  function handleClickBool() {
    if(props.btnGoBool){
      history.push("/Requisitos-Vip-Business");
    }else {
      history.push("/Beneficios-Vip-Business");
    }
  }

  function handleClick() {
    history.push("/Business-Vip");
  }

  return (
    <div className='box-inline'>
      <img onClick={ handleClick } src={ IconWeb.leftRow } alt='Go-Home' className='icon icon-page' />
      <button onClick={ handleClickBool } className='btn btn--vip btn--middle btn--vip-line'> { props.btnRedirectText } </button> 
    </div>
  );
} 

export function BtnBackSwitch(props){

  function handleClick() {
    props.getBackPosition();
  }

  return (
    <div className={ props.typeVip ? 'btn-page--getBack-vip' : 'btn-page--getBack-no-vip' }>
      <img src={IconWeb.leftRow} alt='left-row' onClick={ handleClick } />
    </div>
  )
}
