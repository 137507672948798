import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { LanguageContext } from '../../context/LanguageContext';

import firebase from '../../config/Firebase';

import * as FunForm from '../../functions/Form';

export default class SignInInputs extends Component {

    static contextType = LanguageContext;

    constructor(){
        super();
        this.state = {
            nameUser: '',
            passwordUser: '',
            errorBox: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.validateUser = this.validateUser.bind(this);
    }
   
    handleChange(event){ this.setState({ [event.target.name]: event.target.value }); }

    componentDidMount(){ FunForm.showErrorMsg( [false, false, false], false); }

    async validateUser(){  // Validate Email/LinkName/user input introduced by user to log
        if(this.state.nameUser !== "" && this.state.passwordUser !== ""){
            if(FunForm.validateEmail(this.state.nameUser)){  this.login(this.state.nameUser, this.state.passwordUser);  } 
            else{ var user = await this.getUser();
                if( user[1] !== "" ){ this.login(user[1], this.state.passwordUser); }
                else{ this.setState({ errorBox: true }, () => { FunForm.showErrorMsg([true, false, false], false) }) } 
            }
        } else{ this.setState({ errorBox: true }, () => { FunForm.showErrorMsg([false, false, true], false) }) }        
    }

    async getUser(){  // Get data according to user to use on Login function
        var db = firebase.firestore();
        var usersRef = db.collection("usuarios");
        var email = "", id;

        if(this.evaluateNameUser()){ usersRef = usersRef.where("linkname", "==", this.state.nameUser); } 
        else{ usersRef = usersRef.where("link", "==", this.state.nameUser); }

        await usersRef.get().then(function(querySnapshot) {  
            console.log('size', querySnapshot.size);
            querySnapshot.forEach(function(doc) {            
                console.log(doc.id, " => ", doc.data().correo);
                email = doc.data().correo; id = doc.id; 
            });
        }).catch(function(error) { console.log('Error -> Email ID -> ', error);
            email = ''; id = ''; 
        });

        return [ id, email.toString() ];
    }

    evaluateNameUser = () => { // Detect if User has written a UserName or LinkName based on LinkName syntax
        var sTest = this.state.nameUser.split('').reverse().join('');
        var iCount = 0;

        for (const iIndex in sTest) {
            if(iCount < 4){ if (!isNaN(parseInt(sTest[iIndex]))) { iCount++; } else{ iCount = 0; } } 
            else { if (!isNaN(parseInt(sTest[iIndex]))) { iCount++; } break; }
        } if(iCount === 4){ return true; }else{ return false; }
    }

    login(emailUser, passwordUser){  // Log with Email and Password
        console.log('Email ->>>>>>>>>>>>', emailUser); // dannyrpc
        firebase.auth().signInWithEmailAndPassword(emailUser, passwordUser).then(
            (user) => { 
                console.log('user', user);   
                console.log('user Inputs, ', user.user.uid);
                this.props.evaluateUser(); 
            } 
        ).catch( (error) => {
            
            switch (error.code) {
                case 'auth/email-already-in-use': console.log(`Email address ${emailUser} already in use.`);
                  break;
                case 'auth/invalid-email': console.log(`Email address ${emailUser} is invalid.`);
                  break;
                case 'auth/user-not-found': console.log(`Email address ${emailUser} is not registered.`);
                  break;
                case 'auth/wrong-password': FunForm.showErrorMsg([false, true, false], false);  console.log(`Wrong Password ${emailUser}`);
                  break;
                case 'auth/operation-not-allowed': console.log(`Error during sign up.`);
                  break;
                default: console.error('Error No encontrado como parametro --- > revisar', error);
            }
            
        });

    }

    render(){
        const { dictionary } = this.context; 

        return(<>
            <div className='form__content'>
                                
                <div className='form__block'>
                    <input required type='text' onChange={ this.handleChange } name='nameUser' className='input--vip' placeholder={ dictionary.Sections.Login.UpLoadStatus.placeholder.myLinkUser }/>
                    <input required type='password' onChange={ this.handleChange } name='passwordUser' className='input--vip' placeholder={ dictionary.Sections.Login.UpLoadStatus.placeholder.userPassword }/>                    
                    {
                        this.state.errorBox ? 
                            <div className='alert alert--danger alert--vip'>
                                <span id='msg-error-0'> { dictionary.Sections.Login.UpLoadStatus.ErrorMsg[0] } </span>
                                <span id='msg-error-1'> { dictionary.Sections.Login.UpLoadStatus.ErrorMsg[1] } </span>
                                <span id='msg-error-2'> { dictionary.Sections.Login.UpLoadStatus.ErrorMsg[2] } </span>
                            </div>
                        : ''
                    }
                </div>

            </div>

            <div className='box-inline box-inl--column'>
                <button className='btn btn--vip btn--middle' onClick={ this.validateUser } > { dictionary.GlobalPage.btn.login } </button>
                <Link to='/Reservar-Vip' className='no-btn'> { dictionary.GlobalPage.btn.register } </Link>               
            </div>
        </>)
    }
}