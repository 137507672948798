import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import ReactHtmlParser from 'react-html-parser';
import { Hands } from '../../../assets/images/Images';
import { TitlePersonal } from '../../utilities/Titles';
import { CharactersVip } from '../../utilities/StaticComponents';

export default function Description(props){

    const { dictionary } = useContext(LanguageContext);

    return(<>
        <section className='modular-container --mod-space' data-aos={'zoom-in'}>
            { props.WithTitle ? <TitlePersonal typeVip={true} /> : '' }
            { props.WithTitle ? <CharactersVip /> : '' }
            { 
                dictionary.DescriptionVip.filter( (data) => { 
                    return data.id === ( props.Business ? 1 : 2 ) }).map( (data, index) => 

                    <React.Fragment key={index}>
                        <div className='description'>
                            
                            <div className='description__content des-con--vip-big'>
                                { ReactHtmlParser(data.description) }
                            </div>

                            <div className='description__box'>
                                <div className='box-inline box-inl-x'>
                                    <img className='des-box--bg-hand-left' src={ Hands.handLeftGolden } alt='left-hand' />
                                    <div className='description__content-dialog des-con--vip left-hand'> <p>{ data.dialogs.left }</p> </div>
                                </div>
                                <div className='box-inline box-inl-x'>
                                    <img className='des-box--bg-hand-right' src={ Hands.handRight } alt='right-hand'/>
                                    <div className='description__content-dialog des-con--vip right-hand'> <p>{ data.dialogs.right }</p> </div>
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
                ) 
            }    
        </section>
    </>)
}    