import React, { useContext } from "react";
import { LanguageContext } from '../context/LanguageContext';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

import ReactHtmlParser from 'react-html-parser';

SwiperCore.use( [ Navigation, Pagination, Autoplay ] );

function Slider() {

    var es_chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    if(es_chrome){
        window.scrollTo(0, 100);
    }else{
        window.scrollTo(0, 50);
    }
    
    const slides = [];
    const { dictionary } = useContext(LanguageContext);
    
    slides.push(  
        dictionary.MainSliderHow.map( (item, index) =>
            
            <SwiperSlide key={`slide-${index}`} tag='div'>
                    <div className='slider__imgs-box'>
                            { 
                                item.imgs.map( (subItem, index) => 
                                    <React.Fragment key={index}>
                                        <img src={require(`../assets/images/slider/${subItem.img}`).default}
                                            alt='img' className={'slider-img ' + subItem.cssImg } />                                
                                    </React.Fragment>
                                )
                            }
                    </div>
                    <div className='slider__content'>
                        <strong className='title-big'> { item.titleSlider } </strong>
                        { 
                            item.infoSlider.map( (subItem) => 
                                ReactHtmlParser(subItem.info) 
                            )
                        }
                    </div>
            </SwiperSlide>  
        )    
    )
    
    return ( <>  
        <Swiper tag='section' wrapperTag='div' id='main' data-aos={'zoom-in'}
            pagination={{ clickable: true }}
            autoplay={{ delay: 6000 }} 
            navigation loop={true} >
            { slides } 
        </Swiper>
    </> )
}

export default Slider;