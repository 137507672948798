import React from 'react';

import { useHistory } from 'react-router-dom';
import firebase from '../config/Firebase';

import ContactUsForm from '../components/forms/ContactUs';
import { TitleContact } from '../components/utilities/Titles';
import { BtnRedirectHome } from '../controllers/Buttons/Buttons';
import { setDocTitle } from '../functions/Browser';

export default function ContactUs(){

    let history = useHistory();

    window.scrollTo(0, 0);
    setDocTitle('Contacto');

    async function upLoadDataContact(dataForm){
 
        try {

            let data = {
                "name": dataForm.name, 
                "surname": dataForm.lastname, 
                "email": dataForm.email,
                "text": dataForm.description
            }

            // ----------------------------- Contact Firebase

            var db = await firebase.firestore();
            var usersRef = await db.collection("ContactWeb");
   
            usersRef.add( data ).then(
                () => { console.log('Document successfully written Contact!'); }
            ).catch( (error) => { console.log('Error writing document', error); });

            // ------------------------------ console.log('DATA', dataForm);

            const URL = "https://us-central1-my-link-2020.cloudfunctions.net/submitContactRequest";

            const response = await fetch( URL, {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(data)
              });

            if(response){
              //alert('¡Thank You!, we will notify you shortly');
              history.push("/");
            }

        } catch(e){
            console.log('Error de CATCH ', e);
        }
    }

    return (<>
        <main className='full-container ful-con--row'>

            <section className='form'>
                <div className='box-inline box-inl--column'>
                  <div className='icon icon-static--logo-my-link'></div>
                  <TitleContact />
                </div>
                <ContactUsForm setDataFormContact={ upLoadDataContact } />
            </section>

            <BtnRedirectHome typeVip={false} urlAlone={true} />
        </main>
    </>);
}