import React, { useContext } from 'react';

import { LanguageContext } from '../../context/LanguageContext';

export default function PhraseMyLink(props){

    const { dictionary } = useContext(LanguageContext);

    return(
        <div className='box-inline'>
            <strong className={ props.NoInherit ? 'phrase-my-link phrase-my-link--black bold'
            : 'phrase-my-link bold' } > { dictionary.GlobalPage.note.phraseFooter } </strong>     
        </div>
    )
}