import React, { Component } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import { BtnRedirectHome } from '../../../controllers/Buttons/Buttons';
import { setDocTitle } from '../../../functions/Browser';

import ProfilesMenu from '../../../components/menu/ProfilesMenu';
import Description from '../../../components/infographics/no-vip/Description';
import Infographic from '../../../components/infographics/no-vip/InfographicLarge';
import PhraseMyLink from '../../../components/utilities/Phrases';
import { ContactFooterFromNoVip, 
    ContactFooterFromNoVipBusiness,
    ContactFooterFromNoVipBusinessPlus } from '../../../components/footer/ContactFooter';
import { BarSeparationMiniBusiness, BarSeparationMiniBusinessPlus } from '../../../components/utilities/BarLine';

export default class Business extends Component{

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            IdProfile: 0
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        setDocTitle('Business');
    }

    ShowInformation = (id) => {
        this.setState({ IdProfile: id }, () => {
            window.scrollTo(0, 200); 
        } )
    }
    
    render(){

        const { dictionary } = this.context;

        return (
            <>
                <main className='main-container main-container--footer-banner'> 
                   
                    <ProfilesMenu 
                        typeVip={false} 
                        ShowInformation={ this.ShowInformation } 
                        Business={true} 
                    />
                    
                    {
                        this.state.IdProfile === 0 ? 
                            <>
                                <div className='modular-container' >  
                                    <p className=''> { dictionary.Sections.UserInformation.personalFirst } </p>
                                </div>
                                <div className='space-container--1'></div>
                            </>
                        : 
                            <>
                                <div className='box-title' data-aos={'zoom-in'}>  
                                    <h2 className='title-middle'> { this.state.IdProfile === 1 ? dictionary.Sections.ProfilesMenu.business : dictionary.Sections.ProfilesMenu.businessPlus } </h2>
                                </div>
                            </>
                    }

                        <div className='space-container'></div>

                    {
                        this.state.IdProfile !== 0 ?
                            (
                                this.state.IdProfile === 1 ? 
                                    <> 
                                        <BarSeparationMiniBusiness />
                                        <Description Business={true} DescriptionPro={ false } WithTitle={ false } />
                                        <Infographic Business={true} />
                                        <PhraseMyLink />
                                    </>                                
                                :    
                                    <> 
                                        <BarSeparationMiniBusinessPlus />
                                        <Description Business={true} DescriptionPro={ true } WithTitle={ false } />
                                        <Infographic Business={true} Pro={true} />
                                        <PhraseMyLink />
                                    </>
                            )
                        :
                            ''
                    }

                    <div className='space-container'></div>

                    <BtnRedirectHome typeVip={false} urlAlone={false} />
                </main>
                { 
                    this.state.IdProfile === 1 ? 
                        <ContactFooterFromNoVipBusiness />
                    : (
                        this.state.IdProfile === 2 ?                         
                            <ContactFooterFromNoVipBusinessPlus />
                        : <ContactFooterFromNoVip Business={true} />
                    )
                }
            </>
        );
    }
}