import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { LanguageContext } from '../../../context/LanguageContext';

export default class Infographic extends Component {
    
    static contextType = LanguageContext;

    render(){

        const { dictionary } = this.context; 

        let dataCards = dictionary.InfoProSection.filter( (data) => { return data.id === 3 } );

        return (
            <>
                <section className='infographic-container' data-aos={'fade-down'} >
                    {   
                        this.props.horizontal ? 
                            <> 
                                <InfoCardHorizontal data={this.props.info.infoCards} /> 
                            </>     
                        :
                            dataCards.map( (data, index) => 
                                <React.Fragment key={index} >
                                    <InfoCardVertical data={data.infoCards} />                        
                                </React.Fragment>
                            )
                    }
                </section>
            </>
        )
    }
}

function InfoCardVertical(props) {
    return (
        props.data.map( (item, index) => 
            <div className='infographic-box-v' key={index}>
                <div className={'infographic-box-v__img infographic-box-v__img--pro ' }>
                    <div className={'infographic-box-v__img-box icon-' + item.cssIcon}> </div>
                </div>
                <div className='infographic-box-v__content'>
                    <strong className='title-small'>{ item.title }</strong>
                    { ReactHtmlParser(item.infoCard) }
                </div>
            </div>
        )
    )    
}

function InfoCardHorizontal(props) {
    return (
        props.data.map( (item, index) => 
            <div className='infographic-box infographic-box--pro' key={ index } >
                <div className={'infographic-box__img inf-box__img--pro icon-location-0' } > 
                    <div className={'infographic-box__img-box icon-infoPro-' + item.cssIcon } > </div>
                </div>
                <div className='infographic-box__content inf-box__con--pro' id='info-box-con--1' > 
                    { ReactHtmlParser(item.infoCard) }
                </div>
            </div>
        )
    )
}