import React, { Component, useContext } from 'react';
import $ from 'jquery';

import ReactHtmlParser from 'react-html-parser';

import { LanguageContext } from '../../context/LanguageContext';
import { BtnClaimUserVip } from '../../controllers/Buttons/Buttons';
import { Characters } from '../utilities/StaticComponents';

import * as Fun from '../../functions/Form';

export default class ChooseName extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);

        const userNamePos = window.sessionStorage.getItem('ReuseUserName');

        this.state = {
            btnNext: userNamePos ? true : false,
            showMsg: 4,
            name: this.props.userName !== '' ? (this.props.userName) : '',
            business: false,
            nameReserved: '',
            type: 0,
            userPro: false,
            btnPassed: 0
        }

        this.searchUserName.bind(this);
        this.setUserName.bind(this);
        this.handleChange = this.handleChange.bind(this);
                
    }

    componentWillUnmount(){
        window.sessionStorage.removeItem('ReuseUserName');
    }

    componentDidMount(){
        //validateNavigator();
        //$("#nameUser").autoresize({padding: 20, minWidth: 40, maxWidth: 300});

        const userNamePos = window.sessionStorage.getItem('ReuseUserName');
        if(userNamePos){
            this.btnAccept();
        }

        document.getElementById('nameUser').addEventListener('keypress', 
            e => {
                if(e.key === 'Enter' && this.state.name !== ''){
                    if(this.state.showMsg === 0){

                        if(this.state.name !== ''){
                            if(Fun.validateName(this.state.name)){
                                this.setUserName();
                            } else {
                               this.setState( { btnNext: false, showMsg: 3 } );
                            }
                        }else {
                            this.setState({ 
                                btnNext: false, 
                                showMsg: 4,
                                nameReserved: '',
                                business: false 
                            });                     
                        }
                
                    }else {

                        if(this.state.showMsg === 3){
                            this.btnClearMessage();
                        }else {

                            if( (this.state.btnNext === 1 && this.state.btnPassed === 1)
                            || (this.state.btnNext === 3 && this.state.btnPassed === 1) ){
                                this.btnClearMessage(); this.setState({ btnPassed: 0, showMsg: 0 });
                            }else {
                                switch(this.state.btnPassed){
                                    case 0: 
                                            this.btnAccept(); 
                                            this.setState({ btnPassed: 1, showMsg: 0 });
                                            // document.getElementById("validateBtn").click();
                                        break;
                                    case 1: 
                                            if(Fun.validateName(this.state.name)){
                                                this.setUserName(); 
                                                this.setState({ btnPassed: 2, showMsg: 0 });
                                            } else {
                                                this.setState({ btnNext: false, showMsg: 3 });
                                            }
                                            // document.getElementById("acceptGo").click();
                                        break;
                                    default: this.btnAccept(); console.log('Default');
                                        break;
                                }
                            }

                        }

                    }
                    
                }else{
                    this.setState({ 
                        btnNext: false, 
                        showMsg: 4,
                        nameReserved: '',
                        business: false 
                    });  
                }

            }
        );

        /*
        if(this.state.showMsg === 0){
            document.getElementById('nameUser').addEventListener('keypress', 
                e => {  console.log('Btn Acept Go -->', e.key);
                    if(e.key === 'Enter'){
                        this.setUserName();
                    }
                }
            );
        }else {
            if( (this.state.btnNext === 1 && this.state.btnPassed === 1) || (this.state.btnNext === 3 && this.state.btnPassed === 1) ){
                document.getElementById('nameUser').addEventListener('keypress', 
                    e => { console.log( 'clear Message -->', e.key);
                        if(e.key === 'Enter'){
                            this.btnClearMessage();
                            this.setState({ btnPassed: 0 });
                        }
                    }
                );
            }else {
                switch(this.state.btnPassed){
                    case 0: document.getElementById('nameUser').addEventListener('keypress', 
                                e => {  console.log('Btn Acept -->', e.key);
                                        if(e.key === 'Enter'){
                                            this.btnAccept();
                                            this.setState({ btnPassed: 1 });
                                        }
                                    }
                                );
                    break;
            
                    case 1: document.getElementById('nameUser').addEventListener('keypress', 
                                e => {  console.log('setUserName', e.key);
                                        if(e.key === 'Enter'){
                                            this.setUserName();
                                            this.setState({ btnPassed: 2 });
                                        }
                                    }
                                );
                    break;         
                }
            }
        }
        */

    }

    async searchUserName(){
        
        const URLuserName = "https://us-central1-my-link-2020.cloudfunctions.net/isUsernameAvailable?username=" + this.state.name.toLowerCase();

        const response = await fetch( URLuserName, {
            method: 'GET',
            cache: 'no-cache',
            /* 
                headers: {
                    'Access-Control-Allow-Origin': 'https://My-Link.com/Reservar',
                    //'origin': 'https://My-Link.com/Reservar'
                }
            */
        });
        
        if(this.state.name !== ''){
            if(response){
                const responseJson = await response.json();
                console.log('response -->', responseJson);
                if(responseJson){
                    if(responseJson.data.status === 2){
                        this.setState({ 
                            btnNext: false, 
                            showMsg: responseJson.data.status,
                            nameReserved: responseJson.data.name,
                            type: responseJson.data.type,
                            business: responseJson.data.type === 1 ? true : false      
                        });
                    }else {
                        this.setState({ 
                            btnNext: false, 
                            showMsg: responseJson.data.status,
                            nameReserved: '',
                            business: false
                        });
                    }
                }
            }
        }else {                   
            this.setState({ 
                btnNext: true, 
                showMsg: 4,
                nameReserved: '',
                business: false 
            });                     
        }

    }

    handleChange(event){
        if(this.state.name.length >= 2 ){
            this.setState({ name: event.target.value, btnNext: true, showMsg: 4 });
        }else {
            this.setState({ name: event.target.value, btnNext: false, showMsg: 4 });
        } 
    }

    btnAccept = () => {

        if(this.state.name !== ''){
            if(Fun.validateName(this.state.name)){
                this.searchUserName();
            } else {
               this.setState( { btnNext: false, showMsg: 3 } );
            }
        }else{
            this.setState({ 
                btnNext: true, 
                showMsg: 4,
                nameReserved: '',
                business: false 
            }); 
        }

    }

    btnClearMessage = () => {
        this.setState({ name: '', btnNext: false, showMsg: 4 });
    }

    // Enviar datos al padre
    setUserName = (event) => {
        console.log(event)
        var flagPro = false;

        if(event === undefined){
            if(this.state.showMsg === 0){ this.props.setUserName(this.state.name, false); }
            else{ this.btnClearMessage(); }
        }else{
            if(event.target.id === 'claim' || event.target.id === 'claim2' || event.target.id === 'acceptGo'){
                window.localStorage.setItem('userPro', flagPro);
            }else {
                if(event.target.id === 'claimPro'){
                    flagPro = !flagPro;
                    window.localStorage.setItem('userPro', flagPro);
                }
            }
            console.log('flagPro --->', flagPro);
            this.props.setUserName(this.state.name, flagPro, this.state.business);
        }
    }

    render(){  

        const { dictionary } = this.context; 

        return (
            <>
                <section className='form'>
                    <div className='modular-container --mod-space'>               
                    
                        <li className={ this.props.typeVip ? 'icon icon-static--vip-circle ico-sta--big' : 'icon icon-static--logo-my-link' }></li>
                        
                        <div className='box-inline box-inl--column'>
                            <li id='arroba' className='bold'>@<input className={ this.props.typeVip ? 'input-line inp-lin--vip' : 'input-line inp-lin--no-vip' } 
                                type='text' id='nameUser' name='nameUser' required value={this.state.name} onChange={this.handleChange} /></li>
                            <label htmlFor='nameUser'>{ dictionary.FormClaim.ChooseName.label }</label>
                        </div>

                        {   
                            this.state.showMsg !== 4 ?  
                                <ShowMessage 
                                    opcMsg={ this.state.showMsg } 
                                    btnClearMessage={ this.btnClearMessage } 
                                    typeVip={ this.props.typeVip }
                                    business={ this.state.business }
                                    nameReserved={ this.state.nameReserved } 
                                /> 
                            :  ''
                        }

                        {   
                            this.state.btnNext ?
                                    <>
                                        <button id='validateBtn' className={ (this.props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form') } onClick={this.btnAccept} >
                                            { dictionary.GlobalPage.btn.validate } 
                                        </button>
                                    </>
                                : ''
                        }

                        {   this.state.showMsg === 2 && this.props.typeVip === false ?
                        
                                this.state.nameReserved !== '' ? 

                                    this.state.type === 1 ? // 1 es Business
                                        <> 
                                            <div className='box-inline box-inl--column-tr'>
                                                
                                                <button id='claim1' className='btn btn--no-vip btn--form btn--form-big' onClick={(e) => this.setUserName(e)}>
                                                    { dictionary.GlobalPage.btn.claimUserMini } 
                                                </button>

                                                <BtnClaimUserVip nameUser={this.state.name} dictionary={ dictionary.GlobalPage.btn.claimUserVipMini } />
                                            
                                            </div>  
                                        </>
                                    :   // Personal
                                        <>
                                            <div className='box-inline box-inl--column-tr'>

                                                <button id='claim2' className='btn btn--no-vip btn--form btn--form-big' onClick={(e) => this.setUserName(e)} >
                                                    { dictionary.GlobalPage.btn.claimUserMini } 
                                                </button>

                                                <button id='claimPro' className='btn btn--no-vip-pro btn--form btn--form-big' onClick={(e) => this.setUserName(e)} >
                                                    { dictionary.GlobalPage.btn.claimUserProMini } 
                                                </button>

                                                <BtnClaimUserVip nameUser={this.state.name} dictionary={ dictionary.GlobalPage.btn.claimUserVipMini } />

                                            </div>  
                                        </>
                                : ''
                                
                            : this.state.showMsg === 0 || this.state.showMsg === 2 
                                ?
                                    <> 
                                        <button id='acceptGo' className = { (this.props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form') } onClick={(e) => this.setUserName(e)}>
                                            { dictionary.GlobalPage.btn.accept } 
                                        </button>
                                    </>
                                : ''
                        }
                        
                        {
                            this.state.showMsg === 4 ?
                                <div className='form__block-message'>
                                    { ReactHtmlParser(dictionary.FormClaim.ChooseName.description) }      
                                </div>
                            : ''
                        }

                    </div>
                    
                    <Characters />

                </section>
            </>
        );
    }
}

function ShowMessage(props){
   
    const { dictionary } = useContext(LanguageContext);

    var msg = '';
    // console.log('my-props --->', props);
    switch(props.opcMsg){
        case 0: msg = dictionary.FormClaim.ChooseName.messages.available;
            // '¡ Este nombre de Usuario no está reservado por My-Link, puedes usarlo, apresúrate antes de que alguien más lo gane. !'; 
            break;
        case 3: msg = dictionary.FormClaim.ChooseName.messages.noValid;
            // 'Este nombre de Usuario no es válido, por favor intenta de nuevo.'; 
            break;
        case 2: msg = dictionary.FormClaim.ChooseName.messages.reserved;
            // 'Este nombre de Usuario está reservado por My-Link y está disponible, reclámalo ahora!'; 
            break;
        case 1: msg = dictionary.FormClaim.ChooseName.messages.noAvailable;
            // 'Este nombre de Usuario no está disponible, por favor intenta con otro.*'; 
            break;
        default: msg = ''; 
            break;
    }

    return ( <>
        <div className='form__block-message text-center' >
            {
                props.opcMsg === 2 ? 
                    <>
                        <div className='box-inline box-inl--column'>
                            <p> { props.business ? dictionary.FormClaim.ChooseName.messages.reservedBusinessName : dictionary.FormClaim.ChooseName.messages.reservedPersonName } </p>
                            <input id='inputUserName' className='input--userName-Main' disabled type='text' name='nameReserved' value={ props.nameReserved } />
                            <p> { props.business ? dictionary.FormClaim.ChooseName.messages.reservedBusiness : dictionary.FormClaim.ChooseName.messages.reservedPerson } </p>
                        </div>
                    </> 
                :  <p className='bold'>{ msg }</p>
            }
        </div>
            {
                props.opcMsg === 1 || props.opcMsg === 3 ? 
                    <>
                        <button id='clearMessageBtn' className={ props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form' } onClick={ props.btnClearMessage } >
                            { dictionary.GlobalPage.btn.accept } 
                        </button> 
                    </>
                : ''
            }
    </> )
}
