import React, { Component } from 'react';

import Slider from '../plugins-components/Slider';
import InfographicTypes from '../components/sections/InfographicTypes';
import Reserve from '../components/sections/Reserve';
import VideoMyLink from '../components/sections/VideoMyLink';
import MainInfographic from '../components/infographics/MainInfographic';
import { Store } from '../components/utilities/StaticComponents';
import Footer from '../components/footer/Footer';
import UserName from '../components/sections/UserName';
import { setDocTitle } from '../functions/Browser';

export default class Home extends Component {
    
    componentDidMount(){
        
        setDocTitle('Home');

        if(window.sessionStorage.getItem('scroll-pos-home')){
            window.scrollTo(0, parseInt(sessionStorage.getItem('scroll-pos-home')) );
        }else {
            window.scrollTo(0, 0);
        }
        
    }

    componentWillUnmount(){
        window.sessionStorage.setItem('scroll-pos-home', window.pageYOffset);
    }

    render(){
        return(
            <> 
                <main className='main-container'>
                    <Slider />
                    <InfographicTypes />
                    <UserName />
                    <Reserve />
                    <VideoMyLink />
                    <MainInfographic />
                    <Store />
                </main>
                <Footer />
            </>
        );
    }
}