import React, { useContext } from 'react';

import { Link } from "react-router-dom";
import { LanguageContext } from '../../context/LanguageContext';

function MenuVip(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <>
            <div className='modular-container --mod-space bg--vip' data-aos={'fade-right'} >
                <div className='icon icon-static--vip-circle ico-sta--big'></div>
                <div className='cards'>

                    <Link to='Personal-Vip' className='cards__item'> 
                        <img src={props.cardPersonal} alt='Personal Profile' className='card-icon card-icon--two card-icon--vip'/> 
                        <strong className='title-card'>{ dictionary.Sections.ProfilesMenu.personal }</strong>
                    </Link>

                    <Link to='Business-Vip' className='cards__item'> 
                        <img src={props.cardBusiness} alt='Bussines Profile' className='card-icon card-icon--two card-icon--vip'/> 
                        <strong className='title-card'>{ dictionary.Sections.ProfilesMenu.business }</strong>
                    </Link>

                </div>    
            </div>
        </>
    )
}

function MenuNoVip(props) {

    const { dictionary } = useContext(LanguageContext);

    return (<>
        <div className='modular-container --mod-space' data-aos={'fade-left'}>

            <div className='cards'>
                <Link to='Personal' className='cards__item'> 
                    <img src={props.cardPersonal} alt='PersonalProfile' className='card-icon card-icon--two card-icon--no-vip'/> 
                    <strong className='title-card'>{ dictionary.Sections.ProfilesMenu.personal }</strong>
                </Link>
                <Link to='Business' className='cards__item'> 
                    <img src={props.cardBusiness} alt='BussinesProfile' className='card-icon card-icon--two card-icon--no-vip'/> 
                    <strong className='title-card'>{ dictionary.Sections.ProfilesMenu.business }</strong>
                </Link>
            </div>
        
        </div>
    </>)
}

export { MenuVip, MenuNoVip }