import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import ReactHtmlParser from 'react-html-parser';

export default function ProfileInfo(props) {

    const { dictionary } = useContext(LanguageContext);
    
    return (<>
        {
            dictionary.ProfilesInfo.filter( (profile) => { return profile.id === props.idProfile }).map( (data, index) => 
            
                <section className={'bg-right-down--' + data.id } key={index}>
                        
                    <div className='box-inline box-inl--start'>
                        <div className={ props.typeVip ? 'icon icon-static--vip-business' : 'icon icon-static--no-vip-circle'} > </div>
                        <span className='title-small'>{ data.title }</span>
                    </div>
    
                    <div className='box-inline box-inl--column text-description text-description--final'>
                        { ReactHtmlParser(data.description) }   
                    </div>
    
                </section>
            )
        }    
    </>);
}