import React, { Component } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import  { BtnRedirectHome } from '../../../controllers/Buttons/Buttons';

import ProfilesMenu from '../../../components/menu/ProfilesMenu';
import ContactFooterFromVip from '../../../components/footer/ContactFooter';
import { setDocTitle } from '../../../functions/Browser';

import Description from '../../../components/infographics/vip/Description';
import PhraseMyLink from '../../../components/utilities/Phrases';
import { CharactersBusinessVip } from '../../../components/utilities/StaticComponents';
import Infographic from '../../../components/infographics/vip/Infographic';
import InformationVipComponent from '../../../components/information/InformationVip';
import { BarSeparationGolden } from '../../../components/utilities/BarLine';

export default class BusinessVip extends Component{

  static contextType = LanguageContext;

  constructor(props){
    super(props);
    this.state = {
      IdProfile: 0
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    setDocTitle('Business Vip');
  }

  ShowInformation = (id) => {
    this.setState({ IdProfile: id }, () => {
      window.scrollTo(0, 200); 
    })
  }

  render(){

    const { dictionary } = this.context;

    return (
      <>
        <main className='main-container bg--vip'>   

          <ProfilesMenu 
            typeVip={true} 
            ShowInformation={ this.ShowInformation } 
            Business={true} 
          />
          
          { this.state.IdProfile === 0 ? 
              <>
                <div className='modular-container' >  
                  <p className='' > { dictionary.Sections.UserInformation.personalFirst } </p>
                </div>
              </>
            : 
              <>
                <div className='box-title' data-aos={'zoom-in'}>  
                  <h2 className='title-middle'> 
                    { this.state.IdProfile === 1 ? dictionary.Sections.ProfilesMenu.businessVip : dictionary.Sections.ProfilesMenu.businessVipPlus } 
                  </h2>
                </div>
                <BarSeparationGolden Business={true} />
              </>
          }
          
          <div className='space-container--1'></div>
         
          { this.state.IdProfile !== 0 ?
              ( this.state.IdProfile === 1 ? 
                  <> 
                    <div className='modular-container --mod-space'>
                      <CharactersBusinessVip />
                    </div>
                    <Description Business={true} DescriptionPro={false} WithTitle={false} />
                    <Infographic horizontal={false} Personal={false} />
                    <PhraseMyLink />
                    <InformationVipComponent Business={true} />
                  </>                                
                :    
                  <>
                    <Description Business={true} DescriptionPro={false} WithTitle={false} />
                    <Infographic horizontal={false} Personal={false} />
                    <PhraseMyLink />
                    <InformationVipComponent Business={true} />
                  </>
                )
              : ''
          }

          <div className='space-container--1'></div>

          <BtnRedirectHome typeVip={false} urlAlone={false} />
        </main>
        <ContactFooterFromVip Business={true}/>  
      </>
    );
  }
}