import React, { Component, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import { BtnRedirectToHome } from '../../controllers/Buttons/Buttons';

import { MyLinkBar } from '../utilities/BarLine';

export default class Finally extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.loadDataFunction = this.loadDataFunction.bind(this);        
    }

    loadDataFunction(){
        this.props.setFinish();
    }

    render(){   

        const { dictionary } = this.context; 

        return (
            <>
                <section className='form'> 
                {
                    <div className='modular-container'>

                        <div className={ this.props.typeVip ? 'icon icon-static--vip-personal ico-sta--big' : 'icon icon-static--logo-my-link' } ></div>
            
                        <div className='modular-container --mod-space description-box'> 
                            <p className='text-description-center'> { dictionary.FormClaim.Notification.ready } </p> 
                        </div>
            
                            <BtnRedirectToHome 
                                typeVip={ this.props.typeVip } 
                                dictionary={ dictionary.GlobalPage.btn.sendRequest }
                                loadDataFunction={ this.loadDataFunction }
                                loadData={ true } 
                            />
        
                    </div>
                }
                </section>
            </>
        )
    }
}

export function Ready(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className='modular-container'>

            <div className={ props.typeVip ? 'icon icon-static--vip-personal ico-sta--big' : 'icon icon-static--logo-my-link' } ></div>

            <div className='modular-container --mod-space description-box'> 
                <p className='text-description-center'> { dictionary.FormClaim.Notification.ready } </p> 
            </div>

            <BtnRedirectToHome 
                typeVip={ props.typeVip } 
                dictionary={ dictionary.GlobalPage.btn.sendRequest }
                loadData={ true }
            />

        </div>
    )
}

/*
function Error(props) {

    const { dictionary } = useContext(LanguageContext);

    return(
        <div className='modular-container'>

            <div className={ props.typeVip ? 'icon icon-static--vip-personal ico-sta--big' : 'icon icon-static--logo-my-link' } ></div>

            <div className='modular-container --mod-space description-box'> 
                <div className='text-description-center'>
                    <p> { dictionary.FormClaim.Notification.error.sorry } </p> 
                    <p> { dictionary.FormClaim.Notification.error.description } </p> 
                    <p> { dictionary.FormClaim.Notification.error.solution } </p> 
                </div>
            </div>

            <BtnRedirectToHome 
                typeVip={props.typeVip} 
                dictionary={ dictionary.GlobalPage.btn.accept }
            />    
        </div>
    )
}

function EnterCode(props) {

    const { dictionary } = useContext(LanguageContext);

    return(
        <div className='modular-container --mod-space'>

            <MyLinkBar typeVip={props.typeVip}/>
            <div className={ props.typeVip ? 'icon icon-static--vip-personal ico-sta--big' : 'icon icon-static--logo-my-link' } ></div>

            <div className='description-box'> 
                <div className='text-description-center'>
                    <p> { dictionary.FormClaim.Notification.ok.congratulations } </p> 
                    <p> { dictionary.FormClaim.Notification.ok.userVip } </p> 
                    <p> { dictionary.FormClaim.Notification.ok.enterCode } </p> 
                </div>
            </div>

            <div className='form__block'>
                <input className={props.typeVip ? 'input--vip input-read' : 'input--no-vip input-read'} type='text' name='userCode' value='34dsdfdsf34a' readonly="readonly"/>
            </div>
        </div>
    )
}
*/