import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

export default function MainInfographic(){

    const { dictionary } = useContext(LanguageContext);

    return (
        <section className='middle-container'>
            {
                dictionary.MainInfographic.map( (item, index) => 
                    <React.Fragment key={index}>
                        <div className='info-main__card info-main__card--zigzag' key={item.id} >
                            <div className='info-main__card-box' data-aos={'zoom-out'} >
                                <img src={require(`../../assets/svgs/icons/circle/${item.iconImgSrc}.svg`).default} 
                                    alt={item.iconAltSrc} className='info-icon' />
                                <p className='info-main__text'> { item.infoCard } </p>
                            </div>
                            <img src={require(`../../assets/images/cellphone/${item.phoneImgSrc}.png`).default} 
                                alt='cellphoneImage' className='info-main__card-img'
                                data-aos={ index % 2 == 0 ? 'zoom-in-right': 'zoom-in-left'} />
                        </div>
                    </React.Fragment>                    
                )
            }
        </section>
    );
}