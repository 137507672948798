import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import { BtnsBenefitsRequierements } from '../../controllers/Buttons/Buttons';

import { WordLine } from '../../components/utilities/BarLine';
import Infographic from '../../components/infographics/vip/Infographic';
import { MyLinkVipFooter } from '../../components/footer/Footer';
import { setDocTitle } from '../../functions/Browser';

function ViewBenefitsVip() {

    const { dictionary } = useContext(LanguageContext);

    const data = dictionary.InfoBenefitsVip.filter( (data) => { return data.id === 1 })

    window.scrollTo(0, 0);
    setDocTitle('Beneficios Vip');

    return (
        <>
            <main className='main-container bg--vip'>
                { data.map( (data, index) => 
                    <React.Fragment key={index}>
                        <BtnsBenefitsRequierements btnRedirectText={ data.btnText } btnGoBool={true} />
                        <IconVip Business={ true } />
                        <section className='information-vip information-vip--resist'>
                            <WordLine title={ data.title } cssComplement={''}/>
                            <Infographic horizontal={true} info={ data } />
                        </section>
                    </React.Fragment> 
                )}
                <MyLinkVipFooter /> 
            </main>               
        </>
    )
}

function ViewRequirementsVip(){

    const { dictionary } = useContext(LanguageContext);
    
    const data = dictionary.InfoRequirementsVip.filter( (data) => { return data.id === 1 })            

    window.scrollTo(0, 0);
    setDocTitle('Requerimientos Vip');
    
    return (
        <>
            <main className='main-container bg--vip'>
                { data.map( (data, index) => 
                    <React.Fragment key={index} >
                        <BtnsBenefitsRequierements btnRedirectText={ data.btnText } btnGoBool={false} />
                        <IconVip Business={true} />
                        <section className='information-vip'>
                            <WordLine title={ data.title } cssComplement={''} />
                            <div className='modular-container --mod-space'>
                                <p> { data.description } </p>
                            </div>
                            <Infographic horizontal={true} info={ data } />
                        </section>
                    </React.Fragment>                   
                )}
                <MyLinkVipFooter />
            </main>
        </>
    )
}

function IconVip(props) {
    return(<>
        { props.Business ? <li className='icon-static--vip-business'></li> : <li className='icon-static--vip-personal'></li> }
    </>)
}

export { ViewBenefitsVip, ViewRequirementsVip }