import $ from 'jquery';
import React, { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList } from '../language';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'ES_MX',
  dictionary: dictionaryList.ES_MX
});

// it provides the language context to app
export function LanguageProvider({ children }) {

  var defaultLanguage = '';

  if (localStorage.getItem('rcml-lang') != null){
    var defaultLanguage = localStorage.getItem('rcml-lang');
  } else {
    ipLookUp();
  }

  function ipLookUp () {
    $.ajax('http://ip-api.com/json').then(
      function success(response) {
        console.log('User\'s Country', response.countryCode);
        switch(response.country.toString()){
          case 'MX': defaultLanguage = 'ES_MX'; break;
          case 'DE': defaultLanguage = 'AL_GE'; break;
          case 'FR': defaultLanguage = 'FR_FR'; break;
          case 'IT': defaultLanguage = 'IT_IT'; break;
          case 'PT': defaultLanguage = 'PO_PO'; break;
          default: defaultLanguage = 'EN_US'; break;
        }
      },
      function fail(data, status) {
        console.log('Request failed. Returned status of', status);
        defaultLanguage = 'EN_US';
      }
    );
  }

  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'ES_MX');

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : 'EN_US'
      setUserLanguage(newLanguage);
      localStorage.setItem('rcml-lang', newLanguage);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// get text according to id & current language
export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);
  return languageContext.dictionary[tid] || tid;
};