import React, { Component } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import ProgressBar from './ProgressBar';

export default class LoadingSteps extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            positionStep: 0,
        }
        this.getBackFun = this.getBackFun.bind(this);
        this.positionStep = this.positionStep.bind(this);
    }  

    getBackFun(){          
        if(this.state.positionStep === 0){
            this.props.getBack(true);
        }else {
            this.props.getBack(false);
        }
    } 

    positionStep(){

        if( (window.sessionStorage.getItem("upLoadStatusVideo")) && (this.state.positionStep === 1) ){
            this.props.upLoadVideo();
            window.sessionStorage.deleteItem("upLoadStatusVideo");
        }

        if (this.state.positionStep === 0) {
            if(true){ // it needs to validate if they record a video
                this.setState( (prevState) => ( { positionStep: prevState.positionStep + 1 } ) );
                this.props.upLoadVideo();
            }else {
              //   console.log('No se ha grabado ningun video');
            }
        }else {
            this.getBackFun();
        } 
    }
    
    render(){
        const { dictionary } = this.context; 
        return(
            <>
                <div className='box-inline form__content'>
                    {
                        {
                            0: <> 
                                <button onClick={ this.getBackFun } 
                                    className={ this.props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form' }>{ dictionary.GlobalPage.btn.cancel }</button>
                                {   
                                    (window.sessionStorage.getItem('videoOk') === true) || (this.props.videoOk === true) ?
                                        <button onClick={ this.positionStep } 
                                            className={ this.props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form' }> 
                                            { dictionary.GlobalPage.btn.accept }
                                        </button> 
                                    : ''
                                }
                            </>,
                            1: <> 
                                { 
                                    this.props.loading !== 100 ? 
                                        <ProgressBar loading={ this.props.loading } typeVip={ this.props.typeVip } />                            
                                    :
                                        <>
                                            <div className='box-inline box-inl--column'>
                                                <span className='text-center'>{ dictionary.GlobalPage.note.videoReady }</span>
                                                <button onClick={ this.positionStep } 
                                                    className={ this.props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form' }> 
                                                    { dictionary.GlobalPage.btn.accept } 
                                                </button> 
                                            </div>
                                        </>
                                }
                            </>
                        }[this.state.positionStep]
                    }
                </div>
            </>
        );
    }
}