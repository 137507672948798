import React, { useState } from 'react';
import { Switch, Route } from "react-router-dom";

// Provider Language Context 
import { LanguageProvider } from './context/LanguageContext';

// Home
import Home from './views/Home';
// Infographics Normal Section
import Business from './views/cards/no-vip/Business';
import Personal from './views/cards/no-vip/Personal';

// Vip Section
import BusinessVip from './views/cards/vip/BusinessVip';
import PersonalVip from './views/cards/vip/PersonalVip';
// Reservation 
import ClaimUser from './views/claim/ClaimUser';
import ClaimUserVip from './views/claim/ClaimUserVip';
// UpLoad Status
import UpLoadStatus from './views/upload-status/UpLoadStatus';
// Benefits And Requirements VIP
import { ViewRequirementsVip, ViewBenefitsVip } from './views/information/InformationVip';

// Contact 
import ContactUs from "./views/Contact";

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

//import Page from 'react-page-loading';

function App() {

  // Init AOS animation 
  AOS.init({
    disable: false,
    once: true,
    duration: 1000,
    startEvent: 'DOMContentLoaded',
  });

  return(<>
      <LanguageProvider>
        <Switch>

          {/*<Page loader={"rotate-spin"} color={"#A83E3E"} size={10} >*/}

            <Route exact path='/' component={Home} />
              
            <Route exact path='/Personal' component={Personal} />
            <Route exact path='/Business' component={Business} />
              
            <Route exact path='/Personal-Vip' component={PersonalVip} />
            <Route exact path='/Business-Vip' component={BusinessVip} /> 
              
            <Route exact path='/Subir-Estatus' component={UpLoadStatus} />
              
            <Route exact path='/Reservar' component={ClaimUser} />
            <Route exact path='/Reservar-Vip' component={ClaimUserVip} />

            <Route exact path='/Requisitos-Vip-Business' component={ViewRequirementsVip} />
            <Route exact path='/Beneficios-Vip-Business' component={ViewBenefitsVip} />
              
            <Route exact path='/Contacto' component={ContactUs} />
          {/*</Page>*/}

        </Switch>
      </LanguageProvider>
  </>);
}

export default App;