import React, { Component, useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import { BtnRedirectHome } from '../../controllers/Buttons/Buttons';
import firebase from '../../config/Firebase';

import ClaimForm2 from '../../components/forms/ClaimForm2';
import UpLoadVideo from '../../components/forms/UpLoadVideo';
import Login from '../../components/login/Login';
import { MyLinkBar } from '../../components/utilities/BarLine';

import imgVip from '../../assets/svgs/wallpaper/ilustration_vip.svg';
import { setDocTitle } from '../../functions/Browser';

import Finally from '../../components/forms/Finally';

export default class UpLoadStatus extends Component {

    constructor(props){
        super(props);

        this.state = {
            typeVip: true,
            userName: '',
            typePro: false, 
            business: false,
            nextForm: 0,
            dataForm: {}
        }

        this.goAhead = this.goAhead.bind(this);
        this.setUserName = this.setUserName.bind(this);
        this.setDataForm = this.setDataForm.bind(this);
        this.setReadyView = this.setReadyView.bind(this);

        localStorage.setItem('location_video', 'upLoadStatus');

    }

    /**
     * 
    async dataForm(){
        const URL = "https://us-central1-my-link-2020.cloudfunctions.net/setVIPRequest";
        
        const upLoadData = await fetch ( URL , {
            method: 'POST',
            body: this.state.dataForm
        }).then(
            function name(response) {
                if(response.ok){
                    return true;
                }else {
                    return false;
                }
            }
        )
        
        if(upLoadData){ console.log('Subir Datos '); }
        else{ console.log('Fallo al subir Datos '); }
    
    }
    **/

    componentDidMount(){
        window.scrollTo(0, 0);
        setDocTitle('Login');

        window.sessionStorage.setItem("upLoadStatusVideo", true);
    }

    componentWillUnmount(){
        window.sessionStorage.deleteItem("upLoadStatusVideo");
    }

    goAhead(){ this.setState({ nextForm: 1 }); }   

    setUserName(name, flagPro, business){ 
        this.setState(
            { userName: name, business: business, nextForm: 1 }, 
            () => {
                console.log('UserName ---> ', this.state.userName); 
            }
        ); 
    }

    setDataForm(dataForm2){ 
        this.setState( { dataForm: dataForm2, nextForm: 2 },
            () => { this.dataForm(this.state.dataForm); } );
    }

    setFinish = () => { 
        let data = {}

        data = {
            username: this.state.userName, 
            category: this.state.dataForm.category.category, 
            subcategory: this.state.dataForm.category.subCategory,
            socialmedia: [ 
                { name: this.state.dataForm.social[0].sn, username: this.state.dataForm.social[0].name, followers: this.state.dataForm.social[0].followers }, 
                { name: this.state.dataForm.social[1].sn, username: this.state.dataForm.social[1].name, followers: this.state.dataForm.social[1].followers }, 
                { name: this.state.dataForm.social[2].sn, username: this.state.dataForm.social[2].name, followers: this.state.dataForm.social[2].followers },
                { name: this.state.dataForm.social[3].sn, username: this.state.dataForm.social[3].name, followers: this.state.dataForm.social[3].followers }
            ],
            email: this.state.dataForm.email,
            video: localStorage.getItem('remote_reference')
        }
        this.dataForm( data );                
    }

    setReadyView(flag){
        if(flag){ this.setState({ positionForm: 3 }); }
        else { this.setState({ positionForm: 1 }); }
    }

    async dataForm( data ){

        try {
            /*
            const URL = "https://us-central1-my-link-2020.cloudfunctions.net/setVIPRequest";
            
            const response = await fetch( URL, {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(data)
            });
                    
            if(response){
                console.log('RESPONSE DATA -->', response);
            }
*/
            /// -------------------------------
            var db = await firebase.firestore();
            var usersRef = await db.collection("UpLoadStatus");
    
            usersRef.add( data ).then(
                () => { 
                    console.log('Document successfully written UpLoadStatus!');
                    localStorage.removeItem('remote_reference');
                    localStorage.removeItem('userPro');
                }
            ).catch( (error) => { 
                console.log('Error writing document', error); 
            });
            
        } catch(e){
            console.log('Error de CATCH ', e)
        }
        
    }
/*
    dataForm( data ){
        try {
            const URL = "https://us-central1-my-link-2020.cloudfunctions.net/setVIPRequest";
            // console.log('JSON --> ', JSON.stringify(data));
            fetch( URL, 
                {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': 'POST',
                    },
                    mode: 'no-cors',
                    cache: 'no-cache',
                    body: JSON.stringify(data)
                } ).then( (response) => { 
                    console.log('Response --> ', response);
                } );
        } catch(e) {
            console.log('Error de CATCH ', e)
        }
    }
*/
    render(){
        
        return (
            <>
                <main className='main-container bg--vip'>
                    {
                        {
                            0: <> 
                                <BtnRedirectHome typeVip={true} urlAlone={true} /> 
                                <Present goAhead={this.goAhead} /> 
                                <Login setUserName={this.setUserName} /> 
                            </>,                            
                            1:  <>
                                    <ClaimForm2 
                                        setDataForm={this.setDataForm} 
                                        userName={ this.state.userName } 
                                        typeVip={ this.state.typeVip }
                                        typePro={ this.state.typePro }
                                        business={ this.state.business } />, 
                                </>,
                            2:  <>
                                    <UpLoadVideo 
                                        setReadyView={this.setReadyView} 
                                        userName={this.state.userName} 
                                        typeVip={this.state.typeVip}
                                        typePro={ this.state.typePro }
                                        business={ this.state.business } 
                                    />,
                                </>,    
                            3: <Finally 
                                setFinish={this.setFinish} 
                                typeVip={this.state.typeVip} />,
                            10: <Login setUserName={this.setUserName} />

                        }[this.state.nextForm]
                    }
                </main>
            </>
        )
    }
}

function Present(props) {
    
    const { dictionary } = useContext(LanguageContext);
    
    return (
        <section className='form form--mini'>
            <div className='modular-container'>
                <MyLinkBar typeVip={true} />
                <img src={imgVip} alt='Subir-Estatus-My-link-Vip'/>
                <div className='modular-container --mod-space text-center'>
                    <p>{ dictionary.Sections.UpLoadStatus.description }</p>
                </div>
            </div>
        </section>
    )
}