import React from 'react';

import Youtube from "../../controllers/Video/Youtube";

export default function VideoMyLink(){
    
    return (
        <> 
            <section className='full-container'>
                <div className='modular-container --mod-space'>
                    <div className='icon icon-static--logo-my-link'></div> 
                    <Youtube />
                </div>
            </section>
        </>
    );
}