import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

export function TitlePersonal(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className='box-inline box-inl--column'>
            <div className={ props.typeVip ? 'icon icon-static--vip-personal' : 'icon icon-static--logo-my-link' } > </div>
            <h2 className='title-middle'> 
                { props.typeVip ? dictionary.Sections.PersonalMenu.titleVip : dictionary.Sections.PersonalMenu.titleNoVip } 
            </h2>
        </div>
    )
}

export function TitleBusiness(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className='box-inline box-inl--column'>
            <div className={ props.typeVip ? 'icon icon-static--vip-business' : 'icon icon-static--no-vip-circle' } > </div>
            <h2 className='title-middle'> { props.typeVip ? dictionary.Sections.BusinessMenu.titleVip : dictionary.Sections.BusinessMenu.titleNoVip } </h2>
        </div>
    )
}

export function TitleBusinessOnly(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className=''>
            <h2 className='title-middle'> { props.profileId === 4 ? dictionary.Sections.ProfilesMenu.business : dictionary.Sections.ProfilesMenu.businessPlus } </h2>   
        </div>
    )
}

export function TitlePersonalOnly(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className=''>
            <h2 className='title-middle'> { props.profileId === 1 ? dictionary.Sections.ProfilesMenu.personal : dictionary.Sections.ProfilesMenu.personalPro } </h2>
        </div>
    )
}

export function TitlePersonalShow(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className='box-inline box-inl--column'>
            <div className={ props.typeVip ? 'icon icon-static--vip-personal' : 'icon icon-static--logo-my-link' } > </div>
            { /*  props.showTitle ? 
                    <h2 className='title-middle'> 
                        { props.typeVip ? dictionary.Sections.PersonalMenu.titleVip : dictionary.Sections.PersonalMenu.titleNoVip } 
                    </h2>
                : ''
                */
            }
        </div>
    )
}

export function TitleBusinessShow(props) {

    const { dictionary } = useContext(LanguageContext);

    return (
        <div className='box-inline box-inl--column'>
            <div className={ props.typeVip ? 'icon icon-static--vip-business' : 'icon icon-static--logo-my-link' } > </div>
            { 
                /*  props.showTitle ? 
                        <h2 className='title-middle'> 
                            { props.typeVip ? dictionary.Sections.BusinessMenu.titleVip : dictionary.Sections.BusinessMenu.titleNoVip }
                        </h2>
                    : ''
                */ 
            }
        </div>
    )
}

export function TitleContact(props){

    const { dictionary } = useContext(LanguageContext);

    return ( <>
        <div className='box-inline'> 
            <strong className='title-middle'> { dictionary.FormClaim.Contact.title } </strong>
        </div>
    </> )
}