import React, { Component } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import { BtnRedirectHome } from '../../../controllers/Buttons/Buttons';
import { setDocTitle } from '../../../functions/Browser';

import ProfilesMenu from '../../../components/menu/ProfilesMenu';
import Description from '../../../components/infographics/no-vip/Description';
import InfographicLarge from '../../../components/infographics/no-vip/InfographicLarge';
import InformationProComponent from '../../../components/information/InformationPro';

import PhraseMyLink from '../../../components/utilities/Phrases';
import { ContactFooterFromNoVip, ContactFooterFromNoVipPro, ContactFooterFromNoVipPersonal } from '../../../components/footer/ContactFooter';
import { BarSeparationMini } from '../../../components/utilities/BarLine';

export default class Personal extends Component{

  static contextType = LanguageContext;

  constructor(props){
    super(props);
    this.state = {
      IdProfile: 0
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    setDocTitle('Personal');
  }

  ShowInformation = (id) => {
    this.setState({ IdProfile: id });
    window.scrollTo(0, 200); 
  }

  render(){

    const { dictionary } = this.context;

    return ( 
      <>
        <main className={'main-container ' + (this.state.IdProfile === 2 ? 'bg--pro2 ': '') + 'main-container--footer-banner '} >          
          <ProfilesMenu typeVip={false} ShowInformation={ this.ShowInformation } Business={false} />
          {
            <div className='box-title' data-aos={'zoom-in'}>  
              <h2 className='title-middle'> { this.state.IdProfile === 1 ? dictionary.Sections.ProfilesMenu.personal 
                : ( this.state.IdProfile === 2 ? dictionary.Sections.ProfilesMenu.personalPro : '') } </h2>
            </div>
          }
          { this.state.IdProfile !== 0 ? 
                ( this.state.IdProfile === 1 ?
                  <>
                    <BarSeparationMini Pro={false} />
                    <section className='' >
                      <Description Business={false} DescriptionPro={false} WithTitle={false} />
                      <InfographicLarge Business={false} Pro={false} />
                      <PhraseMyLink />
                    </section>
                  </>
                :
                  <>
                    <BarSeparationMini Pro={true} />
                    <section className='' >
                      <Description Business={false} DescriptionPro={true} WithTitle={false} />
                      <InfographicLarge Business={false} Pro={true} />
                      <PhraseMyLink />
                      <InformationProComponent Business={false} />
                    </section>
                  </> )
            : ''
          }
          <BtnRedirectHome typeVip={false} urlAlone={true} />
        </main>
        { 
          this.state.IdProfile === 2 ? 
            <ContactFooterFromNoVipPro />
          : ( this.state.IdProfile === 1 ?
                <ContactFooterFromNoVipPersonal Business={false} />
              : <ContactFooterFromNoVip Business={false} />
            )
        }
      </>
    );
  }
}