import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

import ReactHtmlParser from 'react-html-parser';
import { TitlePersonal } from '../../utilities/Titles';

export default function Description(props){

    const { dictionary } = useContext(LanguageContext);

    let dataDescription;

    if(!props.DescriptionPro){
        dataDescription = dictionary.DescriptionNoVip.filter( (data) => { return data.id === ( props.Business ? 1 : 2 ) });
    }else {
        dataDescription = dictionary.DescriptionPro.filter( (data) => { return data.id === ( props.Business ? 4 : 3 ) });
    }
    
    return (
    <>
        <section className='modular-container --mod-space' data-aos={'zoom-in'} >
            { props.WithTitle ? <TitlePersonal typeVip={false} /> : '' }
            { 
                props.DescriptionPro ?
                    (
                        props.Business ? <NoVipBuilding dataDescription={ dataDescription } />
                        : <PersonalPro dataDescription={ dataDescription } />
                    )
                :
                    <NoVip dataDescription={ dataDescription } Business={ props.Business } />
            }
        </section>
    </> );
}

function PersonalPro(props) {   
    return (
        props.dataDescription.map( (data, index) => 
            <React.Fragment key={index} >
                <div className='description'>
                    <div className='description__box'>
                        <ImageItems cssCards={data.css.cards} cssCharacters={data.css.characters} />
                        <div className='description__content'>
                            { ReactHtmlParser(data.description) }
                        </div>
                    </div>
                    <div className='description__box des-box--bg-hands'>
                        <div className='box-inline'>
                            <div className='description__content-dialog des-con--no-vip'> <p> { data.dialogs.left } </p> </div>
                            <div className='description__content-dialog des-con--no-vip'> <p> { data.dialogs.right } </p> </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>  
        )      
    )
}

function NoVip(props) {

    return (
        props.dataDescription.map( (data, index) => 
            <React.Fragment key={index}>
                <div className='description'>
                    <div className='description__box'>
                        <ImageItems cssCards={data.css.cards} cssCharacters={data.css.characters}/>
                        <div className='description__content'>
                            { ReactHtmlParser(data.description) }
                        </div>
                    </div>

                    <div className={'description__box ' + ( props.Business ? 'des-box--bg-hands2' : 'des-box--bg-hands' ) } >
                        <div className='box-inline'>
                            <div className='description__content-dialog des-con--no-vip'> <p> { data.dialogs.left } </p> </div>
                            <div className='description__content-dialog des-con--no-vip'> <p> { data.dialogs.right }  </p> </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    )
}

function NoVipBuilding(props){
    
    return (
        props.dataDescription.map( (data, index) => 
            <React.Fragment key={index}>
                <div className='description'>
                    <div className='description__box'>
                        <ImageBuilding cssCards={data.css.cards} />
                        <div className='description__content'>
                            { ReactHtmlParser(data.description) }
                        </div>
                    </div>
                    <div className='description__box des-box--bg-hands3'>
                        <div className='box-inline'>
                            <div className='description__content-dialog des-con--no-vip'> <p> { data.dialogs.left } </p> </div>
                            <div className='description__content-dialog des-con--no-vip'> <p> { data.dialogs.right } </p> </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    )
}

function ImageItems(props) {
    return (
        <> 
            <ol className='box-inline' >
                { props.cssCards.map( (item) => 
                    <li className={'box-img box-img--card character-' + item.cssCard} key={item.id}></li>                
                )}                    
            </ol>
            <ol className='box-inline'>
                { props.cssCharacters.map( (item) =>
                    <li className={'box-img box-img--character character-' + item.cssCharacter} key={item.id}></li>                
                )}        
            </ol>
        </>
    )   
}

function ImageBuilding(props) {
    return ( <>
            <ol className='box-inline' >
                { props.cssCards.map( (item) => 
                    <li className={'box-img box-img--itemBuilding itemBuilding--' + item.cssCard} key={item.id} > </li>
                )}                    
            </ol>
    </> )
}