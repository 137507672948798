import $ from 'jquery';

// It will show or hide an Error Message given a Boolean Array. 
const showErrorMsg  = (flags, init) => {
    for(let i = 0; i < flags.length; i++){
        if(init){  // First time, It will hide all messages 
            $('#msg-error-' + i ).hide(); 
        }else {  // If exits an error show it, if not hide it
            if(flags[i] === true){  
                $('#msg-error-' + i ).show();
            }else {
                $('#msg-error-' + i ).hide();
            }
        }
    }
}

// Validate an E-mail Structure 
const validateEmail = (email) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(reg.test(email)){ return true; }
    else{ return false; }
}

// /^(?:[A-Za-z]+|\d+)$/
const validateName = (name) => {
    const regex = /^[a-zA-Z0-9_-]+$/;  
    if(regex.test(name)){ return true; }
    else{ return false; }
}

/*
const getVideoDuration = () => {

}
*/
export { showErrorMsg, validateEmail, validateName };