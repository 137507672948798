import React, { Component } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { ModalViewUserInfo } from '../modal/Modal';

export default class UserName extends Component{

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            profileId: 0
        }
        this.showModal = this.showModal.bind(this);
        this.getBack.bind(this);
    }

    showModal(num){ this.setState({ profileId: num }); }
    getBack = () => { this.setState({ profileId: 0 }); }

    render(){

        const { dictionary } = this.context; 

        return(
            <>
                <section className='full-container bg--gray-light' data-aos={'fade-left'}>
                    
                    <div className='modular-container --mod-space'>
                        <strong className='title-middle'> { dictionary.Sections.UserInformation.title } </strong>
                        <div className='box-inline box-inl--center'>
                            <div className='box-icon box-icon--margin'>
                                <div className='icon icon-static--username'></div>
                                <button className='btn btn--no-vip btn--large btn--large-small' onClick={ () => { this.showModal(1) } }> Username </button>
                            </div>
                            <div className='box-icon box-icon--margin'>
                                <div className='icon icon-static--my-link'></div>
                                <button className='btn btn--no-vip btn--large btn--large-small' onClick={ () => { this.showModal(2) } }> Linkname </button>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    this.state.profileId !== 0 ? 
                        <ModalViewUserInfo profileId={this.state.profileId} getBack={this.getBack} /> 
                    : '' 
                }
            </>
        );
    }
}