import React, { Component } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import { NameBar } from '../utilities/BarLine';

import * as FunForm from '../../functions/Form';

import SelectCategory from '../../controllers/FormInputs/SelectCategory';
import { EmailInput } from '../../controllers/FormInputs/Inputs';

var initialSocial = {
    socialData: [
        { sn: 'facebook', name: '', followers: '' },
        { sn: 'twitter', name: '', followers: '' },
        { sn: 'instagram', name: '', followers: '' },
        { sn: 'youtube', name: '', followers: '' }
    ],
    socialFlags: [
        { id: 1, sn: 'facebook', input: true, iconSelected: true, checkIcon: false },
        { id: 2, sn: 'twitter', input: false, iconSelected: false, checkIcon: false },
        { id: 3, sn: 'instagram', input: false, iconSelected: false, checkIcon: false },
        { id: 4, sn: 'youtube', input: false, iconSelected: false, checkIcon: false }
    ]
}

export default class ClaimUser extends Component {

    static contextType = LanguageContext;

    constructor(props){
        super(props);
        this.state = {
            // Valores a mostrar en pantalla
            errorMsgs: [ false, false, false ],
            // Valores para la dinamica con css (Deben ser el mismo orden con respecto a sn de dataForm y sn de este array de objetos)
            socialFlag: true,
            social: [
                { id: 1, sn: 'facebook', input: true, iconSelected: true, checkIcon: false },
                { id: 2, sn: 'twitter', input: false, iconSelected: false, checkIcon: false },
                { id: 3, sn: 'instagram', input: false, iconSelected: false, checkIcon: false },
                { id: 4, sn: 'youtube', input: false, iconSelected: false, checkIcon: false }
            ],
            // valores que el usuario teclea
            dataForm: {   
                category: {
                    category: '',
                    subcategory: ''
                },
                social: [
                    { sn: 'facebook', name: '', followers: '' },
                    { sn: 'twitter', name: '', followers: '' },
                    { sn: 'instagram', name: '', followers: '' },
                    { sn: 'youtube', name: '', followers: '' }
                ],
                email: ''
            }
        }

        this.getDataForm.bind(this); // It will send data to parent if it is all OK

        this.setItemSocial.bind(this);
        this.iconSelected.bind(this);
        this.validateInputsSocial.bind(this);
        this.handleChange = this.handleChange.bind(this); // 
    }

    getDataForm = () => { 
        if(this.validateData()){ 
            console.log('dataForm', this.state.dataForm);
            this.props.setDataForm(this.state.dataForm); 
        } 
    }

    setHandleChangeCategory = ( category ) => {
        this.setState( 
            prevState => ({ ...prevState,
                dataForm: {
                    ...prevState.dataForm,
                    category
                }
            })
        );
    }

    setHandleChangeEmail = (email) => {
        this.setState(
            prevState => ({
                ...prevState,
                dataForm: {
                    ...prevState.dataForm,
                    email
                }
            })
        )
    }

    validateData(){ // It validates if exists an error, if does, it will call to showErrorMsg function
        let flags = this.state.errorMsgs;  
        // flag = false means that no exists an error
        if(this.state.dataForm.category.subcategory !== '' ){
            flags[0] = false; //console.log('category true');
        } else{ flags[0] = true; }/*console.log('category false');*/

        if(this.state.socialFlag){
            flags[1] = true;
            for(let i = 1; i <= this.state.social.length; i++){
                let objIndex = this.state.social.findIndex((obj => obj.id === i));
                if(this.state.social[objIndex].checkIcon){ //console.log('social true: id -' , i );
                    flags[1] = false; break;
                }
            } //console.log( 'social', flags[1] ); 
        }else {
            flags[1] = false;
        }

        if(FunForm.validateEmail(this.state.dataForm.email) !== false){
           flags[2] = false; // console.log('email true');
        }else{ flags[2] = true; } // console.log('email false');

        this.setState({ errorMsgs: flags });
        
        if(flags.every(v => v === false)){ return true; }
        else{ FunForm.showErrorMsg(flags, false); return false; }
    }

    componentDidMount(){
        FunForm.showErrorMsg(this.state.errorMsgs, true);
        window.scrollTo(0, 0);
    }

    iconSelected(iconName){ // It will validate if a user has clicked an Icon social to locate on screen the social block selected 
        let elementsIndex = this.state.social.findIndex( element => element.sn === iconName );
        let newSocial = [...this.state.social];

        for(let i = 0; i < newSocial.length; i++){
            newSocial[i] = { ...newSocial[i], input: false, iconSelected: false, }
        }
        newSocial[elementsIndex] = { ...newSocial[elementsIndex],
            input: true, iconSelected: true
        }
        this.setState({ social: newSocial });
    }

    validateInputsSocial = (snName, elementsIndexDFsocial) => { // It will validate if input form in block called "social" has been filled by user depending of what social network is
         
        let currentDFSocial = [...this.state.dataForm.social]; // console.log('currentDFSocial:  ', currentDFSocial);
        let elementsIndexSocial = this.state.social.findIndex( element => element.sn === snName );
        var newSocial = [...this.state.social];

        // console.log('elementsIndexSocial:  ', elementsIndexSocial); //console.log('newSocial:  ', newSocial);
        
        if( (currentDFSocial[elementsIndexDFsocial].name === "") || (currentDFSocial[elementsIndexDFsocial].followers === "") ){
            newSocial[elementsIndexSocial] = { ...newSocial[elementsIndexSocial],
                checkIcon: false
            }
        }

        if( (currentDFSocial[elementsIndexDFsocial].name !== "") && (currentDFSocial[elementsIndexDFsocial].followers !== "") ){
            newSocial[elementsIndexSocial] = { ...newSocial[elementsIndexSocial],
                checkIcon: true
            }
        }
        this.setState({ social: newSocial });
    }

    handleChange(event){ // It will update data intruduced by user in the same time he/she write something on inputs form to later validate them
    
        let dataFormCopy = { ...this.state.dataForm }

        let nameTarjet = event.target.name;
        let nameInput = nameTarjet.toString().substring(7,15);
        let snName = nameTarjet.toString().substring(16);

        let elementsIndexDFsocial = dataFormCopy.social.findIndex( element => element.sn === snName ); // Id del objeto 
        let socialObjsCopy = [...dataFormCopy.social];

        switch(nameInput){
            case 'userName': 
                        socialObjsCopy[elementsIndexDFsocial] = { ...socialObjsCopy[elementsIndexDFsocial],
                            name: event.target.value
                        }                
                break;
            case 'follower':
                        socialObjsCopy[elementsIndexDFsocial] = { ...socialObjsCopy[elementsIndexDFsocial],
                            followers: event.target.value
                        }                   
                break;
            default: console.log('error input');
        }

        this.setState( { dataForm: { ...this.state.dataForm, social: socialObjsCopy } },
            () => { this.validateInputsSocial(snName, elementsIndexDFsocial) }
        );
        
    }

    setItemSocial(dataItem, i, dictionary){
        
        let placeholder = Object.keys(dictionary).filter( (key) => { return dataItem.sn === key; } ).reduce((obj, key) => { return { name: dictionary[key].name, followers: dictionary[key].followers } }, {} );
        
        return (
            <React.Fragment key={i} >
                <div className={ dataItem.input !== false ? 'form__block' : ' display-none' } >
                    <input disabled={ !this.state.socialFlag }  value={ this.state.dataForm.social[i].name } className={ this.props.typeVip ? 'input--vip': 'input--no-vip'} 
                        type='text' name={'social-userName-' + dataItem.sn } placeholder={ placeholder.name } onChange={this.handleChange}/>
                    <input disabled={ !this.state.socialFlag } value={ this.state.dataForm.social[i].followers } className={ this.props.typeVip ? 'input--vip': 'input--no-vip'} 
                        type='text' name={'social-follower-' + dataItem.sn } placeholder={ placeholder.followers } onChange={this.handleChange}/>
                </div>
            </React.Fragment>
        )
    }

    setItemIconSocial(dataItem, i){
        return (
            <React.Fragment key={i} >
                <div className='box-icon'>
                    <li className={ dataItem.checkIcon ? 'icon icon--selected icon-sn-color--' + dataItem.sn :
                        (dataItem.iconSelected ? 'icon icon-sn-color--' + dataItem.sn + ' icon--selected' : 'icon icon-sn--' + dataItem.sn) } 
                        id={'icon-sn-' + i} onClick={ () => this.iconSelected(dataItem.sn) }></li>
                    <span className={ dataItem.checkIcon ? ( this.props.typeVip ? 'icon icon-checked icon-checked--vip' : 'icon icon-checked icon-checked--no-vip') : 'display-none' } id={'icon-sn-checked-' + i}></span>
                </div>
            </React.Fragment>
        )
    }

    btnSocialFlag = () => {
        this.setState( (state, props) => {
            return {
                ...state,
                socialFlag: !state.socialFlag    
            }
        } );
    }

    clearInputsSocial = () => {
        this.setState( (state, props) => {
            return {
                ...state,
                social: [...initialSocial.socialFlags],
                dataForm: {
                    ...state.dataForm,
                    ...initialSocial.socialData
                }
            }
        }, console.log('Clear', this.state) );
    }

    btnNoSocial(){

        return (
            <React.Fragment>
                { this.state.social.every(obj => obj.checkIcon === false) ?
                    <div className='modular-container'>
                        <button className={'btn ' + (this.props.typeVip ? 'btn--vip' : 'btn--no-vip') + ' btn--form' } 
                            onClick={ this.btnSocialFlag } > 
                            { this.state.socialFlag ? 'No Tengo Redes' : 'Si Tengo Redes' } 
                        </button>
                    </div>
                    : ''
                }
            </React.Fragment>
        )
    }

    render(){
        
        const { dictionary } = this.context; 

        return (
            <>
                <section className='form'>
                    
                    <NameBar userName={ this.props.userName } 
                        typeVip={ this.props.typeVip } 
                        typePro={ this.props.typePro }
                        business={ this.props.business }
                    />

                    <div className='modular-container form__content'>

                        <p className='text-center'>{ dictionary.FormClaim.ClaimForm.description }</p>

                        <div className='form__block-info text-left'>
                            <label className='label' htmlFor='category'>{ dictionary.FormClaim.ClaimForm.label.category }</label>
                            <div className='form___block'>
                                <SelectCategory typeVip={this.props.typeVip} setCategory={ this.setHandleChangeCategory } />
                            </div>
                            <span id='msg-error-0' className={this.props.typeVip ? 'alert alert--danger alert--vip' : 'alert alert--danger alert--no-vip'}>{ dictionary.FormClaim.ErrorMsg[0] }</span>
                        </div>

                        <div className='form__block-info text-left'>
                            <label className='label' htmlFor='social'> { dictionary.FormClaim.ClaimForm.label.social } </label>
                            <ul className='box-inline' id='social'>
                                {
                                    this.state.social.map( (item, i) => 
                                        this.setItemIconSocial(item, i) 
                                    )
                                }
                            </ul>
                            <div className='form__block'>
                                {
                                    this.state.social.map( (item, i) =>
                                        this.setItemSocial(item, i, dictionary.FormClaim.ClaimForm.placeholder.social )      
                                    )
                                }
                            </div>
                            <small className='note'>{ dictionary.FormClaim.ClaimForm.noteSocial }</small> 
                            <span id='msg-error-1' className={this.props.typeVip ? 'alert alert--danger alert--vip' : 'alert alert--danger alert--no-vip'}> { dictionary.FormClaim.ErrorMsg[1] } </span>
                            { this.btnNoSocial(dictionary.GlobalPage.btn) }
                        </div>

                        <div className='form__block-info text-left'>
                            <label className='label' htmlFor='email'>{ dictionary.FormClaim.ClaimForm.label.email }</label>
                            <EmailInput typeVip={ this.props.typeVip } setEmail={ this.setHandleChangeEmail } />
                            <span id='msg-error-2' className={this.props.typeVip ? 'alert alert--danger alert--vip' : 'alert alert--danger alert--no-vip'}> { dictionary.FormClaim.ErrorMsg[2] } </span>
                        </div>

                        <button className={ this.props.typeVip ? 'btn btn--vip btn--form btn--vip-line' : 'btn btn--no-vip btn--form' } onClick={this.getDataForm}>
                            { dictionary.GlobalPage.btn.next }
                        </button>

                    </div>
                </section>
            </>
        );
    }
}