// ----------------------------------------------------- Icons Main Infographic
import home from './icons/circle/iconos-01.svg';
import chat from './icons/circle/iconos-02.svg';
import beYourself from './icons/circle/iconos-03.svg';
import userDetail from './icons/circle/iconos-04.svg';
import wallet from './icons/circle/iconos-05.svg';
import business from './icons/circle/iconos-06.svg';
import qr from './icons/circle/iconos-07.svg';
import businessIdentity from './icons/circle/iconos-08.svg';

// ---------------------------------------------------- Icons Web Site

import camera from './icons/page/camera.svg';
import gallery from './icons/page/gallery.svg';
import leftRow from './icons/page/left-row.svg';
import rightRow from './icons/page/right-row.svg';
import homePage from './icons/page/home.svg';

import claimName from './icons/page/claim/claim-name.svg';
import claimNameVip from './icons/page/claim/claim-name-vip.svg';

import facebookSign from './icons/social/session/facebook-color.svg';
import googleSign from './icons/social/session/google-color.svg';
import appleSign from './icons/social/session/apple-gray.svg';

// =====================================================

let IconWeb = {
    camera, gallery, leftRow, rightRow, homePage, 
    claimName, claimNameVip,
    googleSign, facebookSign, appleSign 
}

let IconCircle = { 
    home, chat, beYourself, userDetail, wallet, business, qr, businessIdentity
}

export { 
    IconCircle,
    IconWeb 
}