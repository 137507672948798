import React, { Component } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import Infographic from '../infographics/vip/Infographic';

import { WordLine } from '../utilities/BarLine';

export default class InformationVipComponent extends Component {

    static contextType = LanguageContext;

    render(){
 
        const { dictionary } = this.context; 
 
        let dataRequirements = dictionary.InfoRequirementsVip.filter( (data) => { return data.id === (this.props.Business ? 1 : 2) });            
        let dataBenefits = dictionary.InfoBenefitsVip.filter( (data) => { return data.id === (this.props.Business ? 1 : 2) });

        return(<>
            {
                <section className='information-vip'>
                    {
                        dataBenefits.map( (data, index) =>
                            <React.Fragment key={index}>
                                <WordLine title={data.title} cssComplement={''} />
                                <Infographic horizontal={true} info={ data } /> 
                            </React.Fragment>                       
                        )
                    }
                    {
                        dataRequirements.map( (data, index) => 
                            <React.Fragment key={index}>
                                <WordLine title={data.title} cssComplement={''} />
                                <div className='modular-container --mod-space text--vip-description' >
                                    <p> { data.description } </p>
                                </div>
                                <Infographic horizontal={true} info={ data }/>                        
                            </React.Fragment>
                        )
                    }
                </section>
            }
        </>)
    }
}