import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { LanguageContext } from '../../../context/LanguageContext';

export default class InfographicLarge extends Component {

    static contextType = LanguageContext;

    render(){
        
        const { dictionary } = this.context; 
        let dataCards;

        if(this.props.Pro){
            dataCards = dictionary.InfoProSection.filter( (data) => { return data.id === ( this.props.Business ? 2 : 1 ) } );
        } else {
            dataCards = dictionary.InfoNoVipSection.filter( (data) => { return data.id === ( this.props.Business ? 1 : 2) });
        }
        
        return ( <>
            <section className='infographic-container' data-aos={'fade-down'}
                id={ this.props.Business ? ( this.props.Pro ? 'info-con--business-plus' : 'info-con--business' ) : ( this.props.Pro ? 'info-con--personal-pro' : 'info-con--personal' ) } >           
                { 
                    this.props.Pro ? 
                        ( this.props.Business ?
                            dataCards.map( (data, index) => 
                                <React.Fragment key={index} >
                                    <InfoCardLargePlus data={data.infoCards} />
                                </React.Fragment>
                            )
                        :  
                            dataCards.map( (data, index) => 
                                <React.Fragment key={index}>
                                    <InfoCardLargePro data={data.infoCards} />
                                </React.Fragment>
                            )
                        )
                    : 
                        dataCards.map( (data, index) => 
                            <React.Fragment key={index}>
                                <InfoCardLarge data={data.infoCards} />
                            </React.Fragment>
                        )
                }
            </section>
        </> );
    }
}

function InfoCardLarge(props) {
    return (
        props.data.map( (item, index) => 
            <React.Fragment key={index} >
                <div className='infographic-box'>
                    <div className={'infographic-box__img icon-location-' + item.css.location +' icon-info-' + item.css.icon } > </div>
                    <div className='infographic-box__content' id={'info-box-con--' + item.css.location } > 
                        { ReactHtmlParser(item.infoCard) } 
                    </div>
                </div> 
            </React.Fragment>
        )           
    )
}

function InfoCardLargePro(props) {
    
    return (
        props.data.map( (item, index) => 
            <React.Fragment key={index} >
                <div className='infographic-box infographic-box--pro'>
                    <div className={'infographic-box__img inf-box__img--pro icon-location-' + item.css.location } >
                        <div className={'infographic-box__img-box icon-info-' + item.css.icon } > </div>
                    </div>
                    <div className='infographic-box__content inf-box__con--pro' id={'info-box-con--' + item.css.location } > 
                        { ReactHtmlParser(item.infoCard) } 
                    </div>
                </div> 
            </React.Fragment>
        )           
    )
}

function InfoCardLargePlus(props) {
    return (
        props.data.map( (item, index) => 
            <React.Fragment key={index} >
                <div className='infographic-box infographic-box--plus'>
                    <div className={'infographic-box__img inf-box__img--plus icon-location-' + item.css.location } >
                        <div className={'infographic-box__img-box icon-info-' + item.css.icon } > </div>
                    </div>
                    <div className='infographic-box__content inf-box__con--plus' id={'info-box-con--' + item.css.location } > 
                        { ReactHtmlParser(item.infoCard) } 
                    </div>
                </div> 
            </React.Fragment>
        ) 
    )
}