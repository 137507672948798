import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

import ReactHtmlParser from 'react-html-parser';

export default function UserInfo(props) {

    const { dictionary } = useContext(LanguageContext);
    
    return ( <>
        {
            dictionary.UserInfo.filter( 
                (profile) => { return profile.id === props.idProfile}).map( (data, index) => 

                <section className={ 'modular-container bg-user-right-down--' + data.id } key={index}>
                        
                    <div className='box-inline box-inl--start'>
                        <div className={ data.id === 2 ? 'icon icon-static--my-link' :'icon icon-static--username'}></div>
                        <span className='title-small'>{ data.title }</span>
                    </div>

                    <div className='box-inl--column text-description--user'>
                        <div className='text-description text-description--two'>
                            { ReactHtmlParser(data.description) }
                        </div>                    
                        <div className='text-description text-description--full'>
                            { ReactHtmlParser(data.detail) }
                        </div>
                    </div>

                </section>
            )
        }    
    </>);   
}